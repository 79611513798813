import React, { useState } from "react";
import {
  Link as RouterLink,
} from "react-router-dom";

// third party import
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import LoadingButton from '@mui/lab/LoadingButton';

import {
  Alert,
  Box,
  TextField,
  Link
} from "@mui/material";
import { motion } from "framer-motion";

// Project import
import securityApi from "../../apis/securityApi";

let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const LostPasswordForm = ({setIsLoginForm}) => {

  const [errorMessage, setErrorMessage] = useState('');
  const [infoMessage, setInfoMessage] = useState(null);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Provide a valid email address")
      .required("Email is required")
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      verifiedCode: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      await resetPassword()
        .then(() => {
          setInfoMessage("Reset password successfully. Please click on Login link to Sign In to iEqip")
        })
        .catch((err) => {
          setErrorMessage(`${err}. Failed to reset password`);
          setErrors("Failed to reset password. \n" + err)
        });
        setSubmitting(false);
    },
  });

  const resetPassword = async () => {
    try {
      if (!values.email || values.email === '') {
        throw new Error("Email is required");
      }

      if (!values.verifiedCode || values.verifiedCode === '') {
        throw new Error("Verified Code is required. Please click on Get Verification Code and check your email");
      }

      if (!values.password || values.password === '') {
        throw new Error("Password is required");
      }

      if (!values.confirmPassword || values.confirmPassword === '') {
        throw new Error("Confirm Password is required");
      }

      if (values.password !== values.confirmPassword) {
        throw new Error("Password doesn't match");
      }

      setSubmitting(true);
      await securityApi.post('/users/confirmforgotpassword', {
        ConfirmationCode: values.verifiedCode,
        Username: values.email,
        Password: values.password,
      });
    } catch (error) {
      console.error('resetPassword failed', error);
      if (error.response) {
        throw error.response.data.errorMsg; // error from api
      } else {
        throw error;
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleVerificationCode = async () => {
    try {
      setSubmitting(true);
      if (!values.email || values.email === '') {
        throw new Error("Email is required");
      }
      await securityApi.post('/users/resetpassword', {
        Username: values.email,
      });
      setInfoMessage(`We have sent verification code to your email ${values.email}.`)
    } catch (error) {
      console.error('handleVerificationCode failed', error);
      setErrorMessage(`${error}. Failed to send verification code.`);
      setErrors(`${error}. Failed to send verification code.`)
    } finally {
      setSubmitting(false);
    }
  }

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setErrors, setSubmitting } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box
          component={motion.div}
          animate={{
            transition: {
              staggerChildren: 0.55,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
            component={motion.div}
            initial={{ opacity: 0, y: 40 }}
            animate={animate}
          >
            {infoMessage && <Alert onClose={() => setInfoMessage(null)} severity="success">{infoMessage}</Alert>}
            {errors.length > 0 && <Alert onClose={() => {setErrorMessage(''); setErrors([])}} severity="error">{errorMessage}</Alert>}
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email Address"
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <LoadingButton 
              loading={isSubmitting} 
              fullWidth
              size="large"
              onClick={handleVerificationCode}
              variant="contained">
                Step 1 - Get Verification Code
            </LoadingButton>
            <TextField
              fullWidth
              autoComplete="verifiedCode"
              label="Please get your verified code in your email"
              {...getFieldProps("verifiedCode")}
              error={Boolean(touched.verifiedCode && errors.verifiedCode)}
              helperText={touched.verifiedCode && errors.verifiedCode}
            />
            <LoadingButton 
              loading={isSubmitting} 
              fullWidth
              size="large"
              type="submit"
              variant="contained">
                Step 2 - Reset Password
            </LoadingButton>
            <TextField
              fullWidth
              type="password"
              label="Password"
              {...getFieldProps("password")}
              error={!!touched.password && !!errors.password}
              helperText={touched.password && errors.password}
            />
            <TextField
              fullWidth
              type="password"
              label="Confirm Password"
              {...getFieldProps("confirmPassword")}
              error={!!touched.confirmPassword && !!errors.confirmPassword}
              helperText={touched.confirmPassword && errors.confirmPassword}
            />
            <Link
              component={RouterLink}
              variant="subtitle2"
              to="#"
              underline="hover"
              onClick={() => setIsLoginForm(true)}
            >
              Login 
            </Link>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default LostPasswordForm;
