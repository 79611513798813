import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { useDispatch } from "react-redux";

// mui import
import { CssBaseline, ThemeProvider } from "@mui/material";

// third party
import { SnackbarProvider } from "notistack";
import { useSelector } from "react-redux";

// project import
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Tenants from "./scenes/tenants";
import TenantForm from "./scenes/tenants/form";
import Users from "./scenes/users";
import UserForm from "./scenes/users/form";
import Assignments from "./scenes/assignments";
import AssignmentForm from "./scenes/assignments/form";
import Firms from "./scenes/firms";
import FirmForm from "./scenes/firms/form";
import Lookups from "./scenes/lookups";
import Form from "./scenes/form";
import FAQ from "./scenes/faq";
import Login from "./scenes/Auth/Login";
import InspectionSidebar from "./scenes/inspection/Sidebar";
import InspectionForm from "./scenes/inspection/form";
import InspectionReport from "./scenes/reports/inspectionreport";
import ObservationReport from "./scenes/reports/ObservationReport";
// import ObservationOrdering from "./scenes/reports/ObservationOrdering";
// import DiscussionOrdering from "./scenes/reports/DiscussionOrdering";
import ObservationReportGenerator from "./scenes/reports/ObservationReportGenerator";
import { COVERAGE_CHOICE_CITATION_GUID, COVERAGE_CHOICE_COVERED_DISCUSS_ONLY_GUID, REPORT_TYPE } from "./utilities/constants";
import AI from "./scenes/ai";
import { setAccessToken, setLogout } from "./state";
import CoverageDnD from "./scenes/reports/CoverageOrdering";
import securityApi from "./apis/securityApi";

function App() {
  const [theme, colorMode] = useMode();
  const dispatch = useDispatch();

  const isShowInspection = Boolean(useSelector((state) => {
    return state.isShowInspection;
  })); 

  const accessToken = String(useSelector((state) => {
    return state.accessToken;
  }));

  const refreshToken = String(useSelector((state) => {
    return state.refreshToken;
  }));

  const [isSidebar, setIsSidebar] = useState(true);

  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let inactivityTimer;
    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setInterval(() => {
        setIsActive(false);
      }, 60000); // 60 * 1000 = 1 minutes set to inactive
    };

    const handleUserActivity = () => {
      setIsActive(true);
      resetTimer();
    };

    // Initial setup
    resetTimer();

    // Event listeners for user activity and set user to active
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keypress', handleUserActivity);
    window.addEventListener('touchstart', handleUserActivity);

    const checkTokenExpiry = async () => {
      try {
        //console.log('isAccessTokenValid()', isAccessTokenValid(), 'isActive', isActive, 'refreshToken', refreshToken !== null, isActive && refreshToken !== null);
        if (!isAccessTokenValid()) {
          if (isActive && refreshToken == null) {
            try {
              const result = await securityApi.post("/users/refreshaccesstoken", {
                refreshToken: refreshToken
              });
              dispatch(
                setAccessToken({
                  token: result.data.body.AuthenticationResult.IdToken,
                  accessToken: result.data.body.AuthenticationResult.AccessToken,
                })
              );
            } catch (refreshTokenError) {
              console.error('ieQip failed to get refresh token:', refreshTokenError);
            }
          } else {
            dispatch(setLogout());
          }
        }
      } catch (error) {
        console.error('ieQip please ignore this error because accessToken is null even we have a check. Error decoding the access token:', error);
      }
    };
    checkTokenExpiry();

    // Set up a timer to periodically check the token's validity
    const tokenExpiryCheckInterval = setInterval(checkTokenExpiry, 60000); // Check every minute

    // Clear the timer when the component unmounts
    return () => {
      clearInterval(tokenExpiryCheckInterval);
      clearTimeout(inactivityTimer);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keypress', handleUserActivity);
      window.removeEventListener('touchstart', handleUserActivity);
    }
    // to disable warning "React Hook useEffect has a missing dependency: 'isAccessTokenValid'"
    // eslint-disable-next-line 
  }, [accessToken, dispatch]);

  const isAccessTokenValid = () => {
    try {
      if (accessToken) {
        const decoded = jwtDecode(accessToken);
        const currentTimestamp = Math.floor(Date.now() / 1000);
  
        // decoded.exp - 1800: 30 minutes. decoded.exp - 3300: 5 minutes.
        // Default expired token in 30 minutes if there is no activity
        if (decoded.exp - 1800 < currentTimestamp) { 
          // Token has expired
          //console.log('Token has expired', decoded.exp, currentTimestamp)
          return false;
        }
        //console.log('checking token', decoded.exp, currentTimestamp, decoded.exp - currentTimestamp);  //3600: 1 hour timestamp
      }
      return true;
    } catch (error) {
      console.error('ieQip please ignore this error because accessToken is null even we have a check. Error decoding the access token:', error);
      return false;
    }
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={1}> {/* 1 second */}
          <CssBaseline />
          {
            isAccessTokenValid() ? 
              <div className="app">
                <Sidebar isSidebar={isSidebar} />
                {isShowInspection && <InspectionSidebar />}
                <main className="content">
                  <Topbar setIsSidebar={setIsSidebar} />
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/tenants" element={<Tenants />} /> 
                    <Route path="/tenants/create" element={<TenantForm />} />
                    <Route path="/tenants/:tenant_id/edit" element={<TenantForm />}></Route>
                    <Route path="/users" element={<Users />} /> 
                    <Route path="/users/create" element={<UserForm />} />
                    <Route path="/firms" element={<Firms />} />
                    <Route path="/firms/create" element={<FirmForm />} />
                    <Route path="/firms/:firm_id/edit" element={<FirmForm />}></Route>
                    <Route path="/lookup" element={<Lookups />}></Route>
                    <Route path="/assignments/create" element={<AssignmentForm />} />
                    <Route path="/assignments/:assignment_uid/edit" element={<AssignmentForm />}></Route>
                    <Route path="/assignments" element={<Assignments />} />
                    <Route path="/form" element={<Form />} />
                    <Route path="/calendar" element={<Calendar />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/inspection/:inspectionUid/citationLanguageCode/:citationLanguageCode" element={<InspectionForm />} />
                    <Route path="/inspection/:inspectionUid/subPartCode/:subPartCode/citationLanguageCode/:citationLanguageCode/lookupcodetype/:lookupcodetype" element={<InspectionForm />} />
                    <Route path="/inspection/:inspectionUid/inspectionreport" element={<InspectionReport />} />
                    <Route path="/inspection/:inspectionUid/citationreport" element={<ObservationReport reportType={REPORT_TYPE.OBSERVATION}/>} />
                    <Route path="/inspection/:inspectionUid/observationordering" element={<CoverageDnD 
                      coverageDndType={REPORT_TYPE.OBSERVATION}
                      coverageChoiceGuid={COVERAGE_CHOICE_CITATION_GUID}
                      systemChoiceField="system_choice_observation" />} />
                    <Route path="/inspection/:inspectionUid/printcitation" element={<ObservationReportGenerator reportType={REPORT_TYPE.OBSERVATION} />}/>
                    <Route path="/inspection/:inspectionUid/discussionreport" element={<ObservationReport reportType={REPORT_TYPE.DISCUSSION}/>} />
                    <Route path="/inspection/:inspectionUid/discussionordering" element={<CoverageDnD 
                      coverageDndType={REPORT_TYPE.DISCUSSION}
                      coverageChoiceGuid={COVERAGE_CHOICE_COVERED_DISCUSS_ONLY_GUID}
                      systemChoiceField="system_choice_discussion" />} />
                    <Route path="/inspection/:inspectionUid/printdiscussion" element={<ObservationReportGenerator reportType={REPORT_TYPE.DISCUSSION}/>}/>
                    {/* {routeList?.length > 0 && routeList.map(route => {
                      switch (route.Code) {
                        case 'ROUTE_FIRM':
                          return <Route path="/firms" element={<Firms />} />;
                        case 'ROUTE_ASSIGNMENT':
                          return <Route path="/assignments" element={<Assignments />} />
                        case 'ROUTE_LOOKUP':
                            return <Route path="/lookup" element={<Lookups />}></Route>;
                        case 'ROUTE_USER':
                          return <Route path="/users" element={<Users />} />;
                        default:
                          return <Route key={route.Code} exact path="/"><Dashboard /></Route>;
                    }})} */}
                    <Route path="/aiupload" element={<AI />} /> 
                  </Routes>
                </main>
              </div>
              :
              <Login /> 
          }
        </SnackbarProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
