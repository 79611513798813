import * as React from 'react';
import { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SignaturePad from 'react-signature-canvas';
import { Box } from '@mui/material';
import assignmentApi from '../../apis/assignmentApi';
import { LoadingButton } from '@mui/lab';

export default function ObservationSignatureDialog({ open, setOpen, tenantId, assignmentSignature, handleAssignmentSignature }) {
  const sigPadRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const clearSignature = () => {
    sigPadRef.current.clear();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: async (event) => { 
            setIsLoading(true);
            try {
                event.preventDefault();
                await assignmentApi.post(`/assignments/${assignmentSignature.assignment_id}/assignmentsignatures`, 
                    { tenant_id: tenantId,
                      assignment_id: assignmentSignature.assignment_id,
                      assign_to_email: assignmentSignature.assign_to_email,
                      signature_url: sigPadRef.current.toDataURL('image/png'),
                      type_id: assignmentSignature.type_id });
                assignmentSignature.signature_url = sigPadRef.current.toDataURL('image/png');
                handleAssignmentSignature(assignmentSignature);
                handleClose();
            } catch (error) {
                console.error("Error saving signature:", error);
                alert("Failed to save signature.");
            } finally {
                setIsLoading(false);
            }         

          },
        }}
      >
        <DialogTitle>{assignmentSignature?.assign_to_email}</DialogTitle>
        <DialogContent >
          <DialogContentText>
            Please sign here
          </DialogContentText>
          <Box sx={{ border: "1px solid grey", padding: "0.1em" }}>
          <SignaturePad 
            ref={sigPadRef}
            canvasProps={{ width: 500, height: 200 }}
            />
          </Box>

        </DialogContent>
        <DialogActions>
          <Button color="info" variant="outlined" onClick={clearSignature}>Clear</Button>
          <Button color="info" variant="outlined" onClick={handleClose}>Cancel</Button>
          <LoadingButton loading={isLoading} color="primary" variant="contained" type="submit">Save</LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
