import { Box } from "@mui/material";
import { Droppable } from "react-beautiful-dnd";
import CoverageDraggable from "./CoverageDraggable";

const CoverageDroppable = ({ systemChoice, questionCitationsPerSystemChoice, coverageDndType, coverageChoice }) => {
  return (
    <Box>
      <Droppable droppableId={systemChoice?.code_table_uid} type={coverageDndType}>
        {(provided) => (
          <Box {...provided.droppableProps} ref={provided.innerRef}>
            {questionCitationsPerSystemChoice?.map((questionCitation, index) => {
              return <CoverageDraggable key={questionCitation.code} questionCitation={questionCitation} index={index} type={coverageDndType}/>;
            })}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </Box>
  );
};

export default CoverageDroppable;
