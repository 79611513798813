import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Typography, Grid, CircularProgress, Box, Backdrop } from "@mui/material";
import styled from "@emotion/styled";
import { COVERAGE_CHOICE_CITATION_GUID, SIGNATURE_REPORT_TYPES } from "../../../utilities/constants";
import inspectionApi from "../../../apis/inspectionApi";
import codeTablePostgresApi from "../../../apis/codeTablePostgresApi";
import assignmentApi from "../../../apis/assignmentApi";
import ObservationSignatureDialog from "../ObservationSignatureDialog";
import IconButton from "@mui/material/IconButton";
import DrawIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import ObservationSignatureAuditDialog from "../ObservationSignatureAuditDialog";
import assignmentAssignToApi from "../../../apis/assignmentAssignToApi";

const IObserved = styled(Typography)({
    fontWeight: 700,
  });

function EmployeeSignature({ inspectionUid }) {
  const [loading, setLoading] = useState(false);
  const [assignment, setAssignment] = useState([]);
  const [assignmentsignatures, setAssignmentsignatures] = useState([]);
  const [allowedAssignTos, setAllowedAssignTos] = useState([]);
  const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
  const [openSignatureAuditDialog, setOpenSignatureAuditDialog] = useState(false);
  const [selectedAssignmentSignature, setSelectedAssignmentSignature] = useState(null);
  const [selectedAssignmentSignatureAudits, setSelectedAssignmentSignatureAudits] = useState([]);

  const loggedInUser = useSelector((state) => {
    return state.user;
  });

  const tenantId = useSelector((state) => {
    return state.tenantId;
  });

  const idToken = useSelector((state) => {
    return state.token;
  });

  useEffect(
    () => {
      (async () => {
        setLoading(true);
        const requestHeader = {
          headers: {
            "x-eqip-tenantid": tenantId,
            Authorization: idToken,
          },
        };
        const [responseInspection, responseCodeTables, responseAssignment] = await Promise.all([inspectionApi.get(`/inspections/${inspectionUid}`), codeTablePostgresApi.get(`/codetables/codetabletypes/6,10`), assignmentApi.get(`/assignments/inspection/${inspectionUid}`, requestHeader)]);

        const assignmentId = responseAssignment?.data.body[0].id;
        const responseAssignmentSignature = await assignmentApi.get(`/assignments/${assignmentId}/assignmentsignatures/typeid/${SIGNATURE_REPORT_TYPES.INSPECTION}`);
        const responseAssignmentAssignTos = await assignmentAssignToApi.get(`/api/assignmentassigntos/assignmentid/${assignmentId}`, requestHeader);

        // Create seperate QuestionCitations array and use it to display
        const tempProtocolQualities = [];
        let citationIndex = 0;
        responseInspection?.data.body[0]?.protocol_quality?.forEach((protocolQuality) => {
          const detail = protocolQuality?.detail;
          if (protocolQuality?.detail?.coverageChoice === COVERAGE_CHOICE_CITATION_GUID) {
            if (!detail.index) {
              detail.index = citationIndex++;
            }
            tempProtocolQualities.push(protocolQuality);
          }
          return undefined;
        });

        tempProtocolQualities.sort((a, b) => a?.index - b?.index);

        setAssignment(responseAssignment?.data.body[0]);
        setAssignmentsignatures(responseAssignmentSignature?.data.body);
        setAllowedAssignTos(getAllowAssignedToUsers(responseAssignmentAssignTos?.data, responseCodeTables.data.body, responseAssignmentSignature?.data.body));
        setLoading(false);
      })();
    },
    // eslint-disable-next-line
    [inspectionUid, tenantId, idToken]
  );

  const getAssignmentSignatureByAssignToEmail = (assignmentSignatures, assign_to_email) => {
    const foundSssignmentsignatures = assignmentSignatures?.filter((signature) => signature.assign_to_email === assign_to_email);
    if (foundSssignmentsignatures.length > 0) {
      return foundSssignmentsignatures[0];
    }
    return null;
  };

  const getAllowAssignedToUsers = (assignmentAssignTos, codeTablesRole, assignmentSignatures) => {
    let allowedAssignToList = [];

    const accessMgmts = assignmentAssignTos.map((assignmentAssignTosItem) => {
      const foundRole = codeTablesRole.filter((item) => item.code_table_type_id === 10 && item.id === assignmentAssignTosItem.role_id)[0];

      const foundAssignmentSignature = getAssignmentSignatureByAssignToEmail(assignmentSignatures, assignmentAssignTosItem.assign_to);

      return {
        user_name: assignmentAssignTosItem.assign_to,
        roleUid: foundRole?.code_table_uid,
        roleName: foundRole?.description,
        signature_url: foundAssignmentSignature?.signature_url,
        assignment_signature_uid: foundAssignmentSignature?.assignment_signature_uid,
      };
    });

    //2.Set allowToSign flag to true to current user, so it can be signed
    //If current user doesn't assign to Assigment.assign_to list, system will not show current user to sign the observation report
    const currentLoginUser = accessMgmts.filter((item) => item.user_name === loggedInUser.email)[0];
    if (currentLoginUser) {
      currentLoginUser.signature_url = getAssignmentSignatureByAssignToEmail(assignmentSignatures, currentLoginUser?.user_name)?.signature_url;
      currentLoginUser.allowToSign = true;
    }

    //3.Finallize the allowedAssignToList
    allowedAssignToList.push(...accessMgmts);

    return allowedAssignToList;
  };

  const handleOpenSignatureDialog = (assignmentSignature) => {
    setOpenSignatureDialog(true);
    assignmentSignature.type_id = SIGNATURE_REPORT_TYPES.INSPECTION;
    setSelectedAssignmentSignature(assignmentSignature);
  }

  const handleDeleteAssignmentSignature = async (assignmentSignature) => {
    setLoading(true);
    try {
      await assignmentApi.delete(
        `/assignments/${assignmentSignature.assignment_id}/assignmentsignatures/${assignmentSignature.assignment_signature_uid}`);
      setAllowedAssignTos(allowedAssignTos.map(item => {
        if (item.user_name === assignmentSignature.assign_to_email) {
          return {
            ...item,
            signature_url: null
          }
        }
        return item;
      }))
    } catch (error) {
      console.error("Error deleting signature:", error);
    } finally {
      setLoading(false);
    }
  }

  const handleAssignmentSignatureAudit = async (assignmentSignature) => {
    setLoading(true);
    try {
      const requestHeader = {
        headers: {
          "x-eqip-tenantid": tenantId,
          "Authorization": idToken
        },
      };
      const response = await assignmentApi.get(
        `/assignments/${assignmentSignature.assignment_id}/assignmentsignatureaudits/${assignmentSignature.assign_to_email}`, requestHeader);
        
      setOpenSignatureAuditDialog(true)
      setSelectedAssignmentSignatureAudits(response?.data.body?.filter(item => item.type_id === SIGNATURE_REPORT_TYPES.INSPECTION));
    } catch (error) {
      console.error("Error auditing signature:", error);
    } finally {
      setLoading(false);
    }
  }

  const handleAssignmentSignature = (assignmentSignature) => {
    setAllowedAssignTos(allowedAssignTos.map(item => {
      if (item.user_name === assignmentSignature.assign_to_email) {
        return {
          ...item,
          signature_url: assignmentSignature.signature_url
        }
      }
      return item;
    }));
  }

  return (
    <Box component="section" >
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container>
        <Grid container item>
          <Grid item xs={12} md={12} >
            <Grid container>
              <Grid item xs={12} md={12}>
                <IObserved variant="h6" gutterBottom>
                  EMPLOYEE(S) SIGNATURE
                </IObserved>
              </Grid>
              {allowedAssignTos?.map((item) => {
                const foundAssignmentSignature = getAssignmentSignatureByAssignToEmail(assignmentsignatures, item.user_name);
                return (
                  <Grid container xs={12} md={12} sx={{ borderBottom: "1px solid grey" }}>
                    <Grid item xs={8} md={8}>
                      {item.user_name} - {item.roleName}
                    </Grid>
                    {item.allowToSign ? (
                      <Grid item xs={1} md={1}>
                        <Grid container xs={12} md={12}>
                          <Grid item xs={4} md={4}>
                            <IconButton
                              size="small"
                              aria-label="sign"
                              color="primary"
                              disabled={item?.signature_url}
                              onClick={() =>
                                handleOpenSignatureDialog({
                                  assignment_id: assignment.id,
                                  assign_to_email: item.user_name,
                                  assignment_signature_uid: foundAssignmentSignature?.assignment_signature_uid,
                                  signature_url: foundAssignmentSignature?.signature_url,
                                  type_id: SIGNATURE_REPORT_TYPES.INSPECTION
                                })
                              }
                            >
                              <DrawIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs={4} md={4}>
                            <IconButton
                              size="small"
                              aria-label="sign"
                              color="primary"
                              disabled={!item?.signature_url}
                              onClick={() =>
                                handleDeleteAssignmentSignature({
                                  assignment_id: assignment.id,
                                  assign_to_email: item.user_name,
                                  assignment_signature_uid: foundAssignmentSignature?.assignment_signature_uid,
                                })
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs={4} md={4}>
                            <IconButton
                              size="small"
                              aria-label="sign"
                              color="primary"
                              onClick={() =>
                                handleAssignmentSignatureAudit({
                                  assignment_id: assignment.id,
                                  assign_to_email: item.user_name,
                                  assignment_signature_uid: foundAssignmentSignature?.assignment_signature_uid,
                                })
                              }
                            >
                              <ManageHistoryIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item xs={4} md={4} />
                    )}
                    <Grid item xs={12} md={12}>
                      <Grid item xs={12} md={12}>
                        {item.signature_url && <img src={item.signature_url} alt="Not Found" width="200" height="40" />}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          {/* <Grid item xs={3} md={3} sx={{ borderLeft: "1px solid grey", padding: "0.5em" }}>
                <Grid item xs={12} md={12}>
                  <IObserved variant="h6" gutterBottom>
                    DATE ISSUED
                  </IObserved>
                </Grid>
                <Grid item xs={12} md={12}>
                  02/05/2016
                </Grid>
              </Grid> */}
        </Grid>
      </Grid>
      <ObservationSignatureDialog 
        open={openSignatureDialog} 
        setOpen={setOpenSignatureDialog}
        assignmentSignature={selectedAssignmentSignature}
        tenantId={tenantId} 
        handleAssignmentSignature={handleAssignmentSignature}/>
      <ObservationSignatureAuditDialog 
        open={openSignatureAuditDialog} 
        setOpen={setOpenSignatureAuditDialog}
        assignmentSignatureAuidts={selectedAssignmentSignatureAudits}
        setAssignmentSignatureAudits={setSelectedAssignmentSignatureAudits}/>
    </Box>
  );
}

export default EmployeeSignature;
