import React, { useEffect, useState } from "react";
import { Button, Grid, TextField, CircularProgress } from "@mui/material";
import noteApi from "../../apis/noteApi";

const NoteForm = ({ code, inspection_uid, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState({});
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await noteApi.get(
        `/notes/inspectionid/${inspection_uid}/referencecode/${code}`
      );
      const data = result.data.body[0];
      setNote(data);
      setTitle(data?.title);
      setMessage(data?.message);
      setLoading(false);
    })();
  }, [code, inspection_uid]);

  const onHandleSave = async () => {
    setLoading(true);
    const selectedNote = {
      ...note,
      tenant_id: 1,
      reference_code: code,
      inspection_uid: inspection_uid,
      title: title,
      message: message,
    };
    await noteApi.put(`/notes`, selectedNote);
    onClose();
    setLoading(false);
  };

  const onHandleCancel = () => {
    onClose();
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {loading && <CircularProgress />}
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        ></TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          multiline
          rows={5}
          maxRows={10}
          fullWidth
          label="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></TextField>
      </Grid>
      <Grid item xs={12}>
        <Button color="primary" onClick={onHandleSave} disabled={loading}>
          Save
        </Button>
        <Button onClick={onHandleCancel}>Cancel</Button>
      </Grid>
    </Grid>
  );
};

export default NoteForm;
