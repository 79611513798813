
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";
import { Grid, CircularProgress, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GetAppIcon from '@mui/icons-material/GetApp';
import axios from 'axios';
import documentApi from "../../apis/documentApi";
import { handleDownloadFileFromS3 } from "../../utilities/helpers";

const Attachment = ({ uploadFileParams }) => {
    const { inspectionUid, code } = uploadFileParams;
    const [loading, setLoading] = useState(false);
    const [attachments, setAttachments] = useState([]);

    const tenantId = useSelector((state) => {
        return state.tenantId;
      }); 

    useEffect(() => {
      (async () => {
        try {
          setLoading(true);
          const res = await documentApi.get(`/documents/inspectionid/${inspectionUid}/referencecode/${code}`);
          setAttachments(res.data.body);
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      })();
    }, [inspectionUid, code]);

    const getDocuments = async () => {
      const res = await documentApi.get(`/documents/inspectionid/${inspectionUid}/referencecode/${code}`);
      return res.data.body;
    }

    const handleUploadFile = async (event) => {
        const foundFile = event.target.files[0];
        setLoading(true);
        const fileUuid = uuidv4();
        try {
            //1. Get upload presigned url
            const s3PresignedUrlResult = await documentApi.post(`/documents/presignedurlupload`, {
                objectKey: `${code}/${fileUuid}`,
                contentType: foundFile.type
            });

            //2. Upload file to s3 using presigned url
            await axios.put(s3PresignedUrlResult.data.body, foundFile);

            //3. Insert record to document table in database
            await documentApi.post(`/documents`, {
                tenant_id: tenantId,
                document_uid: fileUuid,
                reference_code: code,
                inspection_uid: inspectionUid,
                name: foundFile.name
            });

            //4. Save upload file detail to inspection table in database
            if (foundFile?.name) {
              setAttachments(await getDocuments());
            }

        } catch (error) {
            console.error('Uploaded failed', error);
        }
        setLoading(false);
    }

    const handleDownloadFile = async (attachment) => {
        setLoading(true);
        handleDownloadFileFromS3(documentApi, attachment.file_name, attachment.name, '/documents/presignedurldownload');
        setLoading(false);
    }

    const handleDeleteFile = async (attachment) => {
        setLoading(true);
        try {
            await documentApi.delete(`/documents/${attachment.document_uid}`);
            setAttachments(await getDocuments());
        } catch (error) {
            console.error('Delete failed', error);
            setLoading(false);
        }
        setLoading(false);
    }

    return <>
        <Grid item xs={12}>
            <input id="icon-button-file" type="file" onChange={handleUploadFile} />
        </Grid>
        {loading && <CircularProgress />}
        <Grid item xs={12}>
            <Grid container item>
                {attachments && attachments.map(attachment => {
                    return <>
                        <Grid key={attachment.id} item xs={9}>
                            <Typography>{attachment.name}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <IconButton color="secondary" aria-label="delete file" component="span" onClick={() => handleDeleteFile(attachment)}>
                                <DeleteForeverIcon />
                            </IconButton>
                            <IconButton color="secondary" aria-label="download file" component="span" onClick={() => handleDownloadFile(attachment)}>
                                <GetAppIcon />
                            </IconButton>
                        </Grid>
                    </>
                })}
            </Grid>
        </Grid>
    </>
}

export default Attachment;