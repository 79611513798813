import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { Grid, TextField, Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
// import { Autosave } from 'react-autosave';
import { transformCitationLanguage } from "../../utilities/helpers";
import SimpleAccordin from "../../components/SimpleAccordin";
import inspectionApi from "../../apis/inspectionApi";

const ObservationDraggableListItem = ({
  index,
  inspection,
  setInspection,
  questionCitation,
  codeTable,
  citationLanguage,
  citationSpecifically,
  reportType,
}) => {
  const [value, setValue] = useState(citationSpecifically);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (citationValue) => {
    inspection?.protocol_quality.forEach((question) => {
      if (question.code === questionCitation.code) {
        questionCitation.detail.citationSpecifically = citationValue;
      }
    });

    setInspection(inspection);
    setValue(citationValue);
  };

  const updateInspection = async () => {
    setIsLoading(true);
    try {
      await inspectionApi.put(`/inspections/${inspection.inspection_uid}`, inspection);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Draggable draggableId={questionCitation.code} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {questionCitation.detail.systemChoice ===
              codeTable.code_table_uid && (
              <SimpleAccordin title={reportType + ' ' + ( index + 1)} isDragNDrop >
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    {transformCitationLanguage(citationLanguage)}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      variant="outlined"
                      label="Specifically"
                      maxRows={5}
                      value={value}
                      onChange={(e) => handleChange(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      onClick={() => updateInspection()}
                      disabled={isLoading}
                      startIcon={<SaveIcon />}
                    >
                      Save
                    </Button>
                  </Grid>
                  {/* <Autosave data={value} onSave={updateInspection} /> */}
                </Grid>
              </SimpleAccordin>
            )}
          </div>
        )}
      </Draggable>
    </>
  );
};

export default ObservationDraggableListItem;
