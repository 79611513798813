import { Draggable } from "react-beautiful-dnd";
import { Box } from "@mui/material";
import CoverageDroppable from "./CoverageDroppable";
import SimpleAccordin from "../../../components/SimpleAccordin";

const SystemChoiceDraggable = ({ systemChoice, questionCitations, coverageDndType, coverageChoice, index }) => {
  const questionCitationsPerSystemChoice = questionCitations?.filter(
    p => p.detail?.systemChoice === systemChoice?.code_table_uid &&
    p.detail?.coverageChoice === coverageChoice);
  return (
    <Draggable draggableId={systemChoice?.code_table_uid} index={index}>
      {(provided) => (
        <Box {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          {questionCitationsPerSystemChoice?.length > 0 && <SimpleAccordin title={systemChoice?.description} isDragNDrop>
            <CoverageDroppable systemChoice={systemChoice} 
              questionCitationsPerSystemChoice={questionCitationsPerSystemChoice} 
              coverageDndType={coverageDndType} 
              coverageChoice={coverageChoice} />
            </SimpleAccordin>
          }
        </Box>
      )}
    </Draggable>
  );
};

export default SystemChoiceDraggable;
