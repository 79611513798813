import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Button, CircularProgress, Backdrop } from "@mui/material";
// import GeneralInfoReport from './GeneralInfoReport';
import CFRReport from "./CFRReport";
import CitationReport from "./CitationReport";
import ReactToPrint from "react-to-print";
// import ExhibitionReport from "../exhibition/ExhibitionReport";
import EqipAccordin from "../../../components/EqipAccordin";
import styled from "@emotion/styled";
import inspectionApi from "../../../apis/inspectionApi";
import codeTablePostgresApi from "../../../apis/codeTablePostgresApi";
import lookupApi from "../../../apis/lookupApi";
import GeneralReport from "./GeneralReport";
import EmployeeSignature from "./EmployeeSignature";

const InspectionReport = () => {
  const RootStyle = styled("div")({
    flexGrow: 1,
    margin: "20px",
  });
  const reportComponentRef = useRef();
  return (
    <RootStyle>
      <ReactToPrint
        trigger={() => (
          <Button variant="contained" color="primary" style={{ marginBottom: "0.5em" }}>
            Print this out!
          </Button>
        )}
        content={() => reportComponentRef.current}
      />
      <InspectionReportPrintable ref={reportComponentRef} />
    </RootStyle>
  );
};

const InspectionReportPrintable = React.forwardRef((props, ref) => {
  const [inspection, setInspection] = useState(null);
  const [codeTables, setCodeTables] = useState(null);
  const [lookups, setLookups] = useState(null);
  const [loading, setLoading] = useState(false);

  const RootStyle = styled("div")({
    flexGrow: 1,
  });
  const { inspectionUid } = useParams();

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const [responseInspection, responseCodeTables] = await Promise.all([inspectionApi.get(`/inspections/${inspectionUid}`), codeTablePostgresApi.get(`/codetables/codetabletypes/6,7`)]);

        const lookupCodes = responseInspection.data.body[0].protocol_quality
          ?.map((item) => item.code)
          .filter((code) => code)
          .join("','");
        const responseLookupsByCodes = await lookupApi.get(`/lookups/lookupcodes/${lookupCodes}`);
        setInspection(responseInspection.data.body[0]);
        setCodeTables(responseCodeTables.data.body);
        setLookups(responseLookupsByCodes.data.body);
        // setLoading(false);
      } catch (error) {
        console.error(error);
      } 
      finally {
        setLoading(false);
      }
    })();
  },
  // eslint-disable-next-line 
  []);

  return (
    <RootStyle ref={ref}>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <style type="text/css" media="print">
        {
          "\
          @media all {\
          .page-break {\
            display: none;\
          }\
        }\
        \
        @media print {\
          html, body {\
            height: auto !important;\
            overflow: visible !important;\
            -webkit-print-color-adjust: exact;\
          }\
        }\
        \
        @media print {\
          .page-break {\
            margin-top: 1rem;\
            display: block;\
            page-break-before: always;\
          }\
        }\
        \
        @page {\
          size: auto;\
          margin-left: 10mm;\
          margin-right: 10mm;\
          margin-top: 20mm;\
          margin-bottom: 20mm;\
        }\
      "
        }
      </style>
      <EqipAccordin title="General">
        <Grid container>
          <GeneralReport inspection={inspection} codeTables={codeTables} lookups={lookups} />
        </Grid>
      </EqipAccordin>
      <EqipAccordin title="System Coverage">
        <CFRReport inspection={inspection} codeTables={codeTables} lookups={lookups} />
      </EqipAccordin>
      <EqipAccordin title="Citation">
        <Grid container>
          <CitationReport inspection={inspection} />
        </Grid>
      </EqipAccordin>
      <EqipAccordin title="Signature">
        <Grid container>
          <EmployeeSignature inspectionUid={inspectionUid} />
        </Grid>
      </EqipAccordin>
      {/* <EqipAccordin title='Exhibition'>
      <Grid container>
        <ExhibitionReport editable={false} />
      </Grid>
    </EqipAccordin> */}
    </RootStyle>
  );
});

export default InspectionReport;
