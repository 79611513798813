import React from 'react';
import { SubMenu } from 'react-pro-sidebar';
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Item from './item';

const General = ({generals, inspectionUid, selected, setSelected}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return <>
    {generals?.map((general) => {
        return !general.parent_uid && <SubMenu
          key={general.lookup_uid}
          title={general.description}
          style={{
            color: colors.grey[100],
          }}
        >
          {generals
            .filter(element => element.parent_uid === general.lookup_uid)
            .map(childGeneral => <Item
              key={childGeneral.lookup_uid}
              title={childGeneral.description}
              to={`/inspection/${inspectionUid}/citationLanguageCode/${childGeneral.code}`}
              selected={selected}
              setSelected={setSelected}
              />)
          }
        </SubMenu>
    
      })
    }
  </> 
}

export default General;
