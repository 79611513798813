import { Grid, Paper } from "@mui/material";
import styled from "@emotion/styled";

import CoverageReport from "./CoverageReport";

const CFRReport = ({ inspection, codeTables, lookups }) => {
  const SYSTEM_CHOICE_CODE_TABLE = 6;
  const systemChoiceCodeTables = codeTables?.filter(
    (codeTable) => codeTable.code_table_type_id === SYSTEM_CHOICE_CODE_TABLE
  );

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.h3,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));

  return systemChoiceCodeTables &&
    <Grid container spacing={2}>
      {systemChoiceCodeTables.map((systemChoice) => (
        <>
        <Grid key={systemChoice.id} item xs={12}>
            <Item>{systemChoice.description}</Item>
        </Grid>
        <Grid key={systemChoice.id} item xs={12}>
            <CoverageReport
              inspection={inspection}
              lookups={lookups}
              codeTables={codeTables}
              systemChoiceUid={systemChoice.code_table_uid}
            />
        </Grid>
        </>
      ))}
    </Grid>
};

export default CFRReport;
