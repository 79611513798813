import React, { useState } from "react";

function EllipsisText({ text, maxLength = 100 }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const displayText = isExpanded ? text : text.substring(0, maxLength);

  return (
    <div className="ellipsis-text" onClick={toggleExpand}>
      {displayText}
      {!isExpanded && text.length > maxLength && <span>...</span>}
    </div>
  );
}

export default EllipsisText;
