import { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Stack, Button, TextField, Backdrop, CircularProgress, Alert, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { Navigate, Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackbar } from "notistack";

import Header from "../../components/Header";
import securityApi from "../../apis/securityApi";
import { SNACKBAT_AUTO_HIDE_DURATION, TENANT_MASTER } from "../../utilities/constants";
import { useTenants } from "../../hooks/useTenants";
import accessManagementApi from "../../apis/accessManagementApi";

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { enqueueSnackbar } = useSnackbar();
  const [errorMessage, setErrorMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);

  const loggedInUser = useSelector((state) => {
    return state.user;
  });

  const tenantId = useSelector((state) => {
    return state.tenantId;
  });

  const idToken = useSelector((state) => {
    return state.token;
  });

  const { tenants } = useTenants();

  const selectedUser = {
    enteredEmail: "",
    enteredPassword: "",
    reenteredPassword: "",
    enteredTenantId: tenantId,
  };

  const checkoutSchema = yup.object().shape({
    enteredEmail: yup.string().required("required"),
    enteredPassword: yup.string().required("required"),
    reenteredPassword: yup.string().required("required"),
    enteredTenantId: yup.string().required("required"),
  });

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    const selectedTenantId = values.enteredTenantId ?? tenantId;
    try {
      const requestHeader = {
        headers: {
          "x-eqip-tenantid": selectedTenantId,
          Authorization: idToken,
        },
      };
      if (values.enteredPassword !== values.reenteredPassword) {
        setErrorMessage("Password does not match");
        setIsLoading(false);
        return;
      }

      // Create new user in cognito
      await securityApi.post(
        "/users/signup",
        {
          Username: values.enteredEmail,
          Password: values.enteredPassword,
          TenantId: selectedTenantId,
        },
        requestHeader
      );

      const saveUser = {
        tenant_id: selectedTenantId,
        user_name: values.enteredEmail,
        created_by: loggedInUser.email,
      };

      // Create accessmanagement with tenantId
      await accessManagementApi.post(`/accessmanagements`, saveUser, {
        headers: {
          "x-eqip-tenantid": selectedTenantId,
        },
      });

      setErrorMessage("");
      enqueueSnackbar(`User ${values.enteredEmail} has created. Please check your email to verify your account.`, {
        variant: "success",
        autoHideDuration: SNACKBAT_AUTO_HIDE_DURATION,
      });
      setIsRedirect(true);
      setIsLoading(false);
    } catch (err) {
      setErrorMessage(`Fail to create user. ${err}`);
      // Handle Error Here
      setIsLoading(false);
      console.error(err);
    }
  };

  return (
    <Box m="20px">
      {isRedirect && <Navigate to="/users" />}
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Header title="USER" subtitle="Create a new user" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={selectedUser}
        validationSchema={checkoutSchema}
        enableReinitialize={true} // Important: allow to reload data on useEffect
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {errorMessage.length > 0 && (
                <Alert onClose={() => setErrorMessage("")} severity="error" sx={{ gridColumn: "span 4" }}>
                  {errorMessage}
                </Alert>
              )}
              <TextField fullWidth type="text" label="Email" onBlur={handleBlur} onChange={handleChange} value={values.enteredEmail} name="enteredEmail" error={!!touched.enteredEmail && !!errors.enteredEmail} helperText={touched.enteredEmail && errors.enteredEmail} sx={{ gridColumn: "span 4" }} />
              <TextField fullWidth type="password" label="Password" onBlur={handleBlur} onChange={handleChange} value={values.enteredPassword} name="enteredPassword" error={!!touched.enteredPassword && !!errors.enteredPassword} helperText={touched.enteredPassword && errors.enteredPassword} sx={{ gridColumn: "span 4" }} />
              <TextField fullWidth type="password" label="Confirm Password" onBlur={handleBlur} onChange={handleChange} value={values.reenteredPassword} name="reenteredPassword" error={!!touched.reenteredPassword && !!errors.reenteredPassword} helperText={touched.reenteredPassword && errors.reenteredPassword} sx={{ gridColumn: "span 4" }} />
              {tenantId === TENANT_MASTER && (
                <FormControl sx={{ gridColumn: "span 4" }}>
                  <InputLabel id="roleId" fullWidth>
                    Tenant
                  </InputLabel>
                  <Select labelId="roleListId" id="roleListId" fullWidth label="Tenant" onBlur={handleBlur} onChange={handleChange} value={values.enteredTenantId} name="enteredTenantId">
                    {tenants.map((option) => (
                      <MenuItem key={option.tenant_uid} value={option.tenant_uid}>
                        {option.tenant_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Stack direction="row" spacing={1}>
                <Button type="submit" color="info" variant="outlined" component={Link} to="/users" startIcon={<CancelIcon />} onClick={() => setSubmitting(false)}>
                  Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained" disabled={isLoading} startIcon={<SaveIcon />} onClick={() => handleFormSubmit(values)}>
                  Save
                </Button>
              </Stack>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Form;
