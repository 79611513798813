import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Stack, Button, TextField, Select, MenuItem, InputLabel, FormControl, Backdrop, CircularProgress, Alert } from "@mui/material";
// import FaceIcon from "@mui/icons-material/Face";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Navigate, useParams, Link } from "react-router-dom";
import { Formik } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackbar } from "notistack";
import { FIRM_TYPE, ROLE_LEAD_USER, ROLE_SUPERVISOR } from "../../utilities/constants";

import Header from "../../components/Header";
import securityApi from "../../apis/securityApi";

import { getDatabaseDate } from "../../utilities/helpers";
import { SNACKBAT_AUTO_HIDE_DURATION } from "../../utilities/constants";
import assignmentApi from "../../apis/assignmentApi";
import firmApi from "../../apis/firmApi";
import codeTablePostgresApi from "../../apis/codeTablePostgresApi";
// import accessManagementApi from "../../apis/accessManagementApi";
import assignmentAssignToApi from "../../apis/assignmentAssignToApi";

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { enqueueSnackbar } = useSnackbar();
  const [errorMessage, setErrorMessage] = useState("");
  const { assignment_uid } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);

  const [assignmentUid, setAssignmentUid] = useState("");
  const [selectedAssignment, setSelectedAssignment] = useState({
    subject: "",
    assignmentDate: new Date(),
    firm: "",
    firm_to_be_audited: "",
    completeDate: "",
    priority: "",
    pac: "",
    operationType: "",
    assignmentStatus: "",
    targetCompleteDate: new Date(),
    assignTo: "",
    assignToRole: "",
  });
  const [firms, setFirms] = useState([]);
  const [firmsToBeAudited, setFirmsToBeAudited] = useState([]);
  const [userList, setUserList] = useState([]);
  const [codeTables, setCodeTables] = useState([]);
  // const [assignedUsers, setAssignedUsers] = useState([]);
  // const [accessManagements, setAccessManagements] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [assignmentAssignTos, setAssignmentAssignTos] = useState([{ id: 0, assign_to: "", role_id: 0 }]);

  const loggedInUser = useSelector((state) => {
    return state.user;
  });

  const tenantId = useSelector((state) => {
    return state.tenantId;
  });

  const idToken = useSelector((state) => {
    return state.token;
  });

  const checkoutSchema = yup.object().shape({
    subject: yup.string().required("required"),
    assignmentDate: yup.date(),
    firm: yup.string().required("required"),
    firm_to_be_audited: yup.string().required("required"),
    targetCompleteDate: yup.date(),
    priority: yup.string().required("required"),
    pac: yup.string().required("required"),
    operationType: yup.string().required("required"),
    assignmentStatus: yup.string().required("required"),
    assignTo: yup.string(),
  });

  const requestHeader = {
    headers: {
      "x-eqip-tenantid": tenantId,
      Authorization: idToken,
    },
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const [responseCodeTable, responseFirm, responseUsers ] = 
        await Promise.all([
          codeTablePostgresApi.get(`/codetables/codetabletypes/1,2,3,5,10`), 
          firmApi.get(`/firms`, requestHeader), securityApi.get(`/users`, requestHeader)
        ]);

      setCodeTables(responseCodeTable.data.body);
      setFirms(responseFirm.data.body.filter((firm) => firm.firm_type_id === FIRM_TYPE.AUDITING_FIRM));
      setFirmsToBeAudited(responseFirm.data.body.filter((firm) => firm.firm_type_id === FIRM_TYPE.FIRM_TO_BE_AUDITED));
      setUserList(responseUsers.data.body);
      fetchAssignedUsers();
      // setAccessManagements(getAccessManagementsWithRole(responseCodeTable.data.body, responseAccessManagements.data.body));
      setIsLoading(false);
      setRoleList(responseCodeTable.data.body.filter((codeTable) => codeTable.code_table_type_id === 10));
    } catch (err) {
      // Handle Error Here
      setIsLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // const getAccessManagementsWithRole = (codeTablesRole, accessManagements) => {
  //   const accessMgmts = accessManagements.map((accessManagement) => {
  //     const foundRoles = codeTablesRole.filter((item) => item.code_table_type_id === 10 && item.code_table_uid === accessManagement.roles[0].id);
  //     if (foundRoles.length > 0) {
  //       const foundRole = foundRoles[0];
  //       return {
  //         user_name: accessManagement.user_name,
  //         roleUid: accessManagement.roles[0].id,
  //         roleName: foundRole?.description,
  //         roleId: foundRole?.id,
  //       };
  //     } else {
  //       return {
  //         user_name: accessManagement.user_name,
  //         roleUid: 0,
  //         roleName: "",
  //         roleId: 0,
  //       };
  //     }
  //   });
  //   return accessMgmts;
  // };

  // const getRoleByUserName = (userName) => {
  //   return accessManagements.filter((accessManagement) => accessManagement.user_name === userName)[0]?.roleName;
  // };

  const fetchAssignedUsers = async () => {
    if (!assignment_uid) return;
    const result = await assignmentApi.get(`/assignments/${assignment_uid}`);
    const assignment = result.data.body[0];
    setSelectedAssignment({
      id: assignment.id,
      subject: assignment.subject,
      assignmentDate: assignment.assignment_date,
      firm: assignment.firm_id,
      firm_to_be_audited: assignment.firm_to_be_audited_id,
      completeDate: assignment.target_complete_date,
      priority: assignment.priority_id,
      pac: assignment.pac_id,
      operationType: assignment.operation_type_id,
      assignmentStatus: assignment.assignment_status_id,
      targetCompleteDate: assignment.target_complete_date,
      assignTo: assignment.assign_to,
    });

    setAssignmentUid(assignment_uid);
    // setAssignedUsers(assignment.assign_to ? assignment.assign_to.split(",") : []);

    const responseAssignmentAssignTos = await assignmentAssignToApi.get(`/api/assignmentassigntos/assignmentid/${assignment.id}`, requestHeader);
    setAssignmentAssignTos(responseAssignmentAssignTos.data);
  };

  // const handleAssignToChange = (event) => {
  //   if (assignedUsers.indexOf(event.target.value) < 0) {
  //     setAssignedUsers([...assignedUsers, event.target.value]);
  //   }
  // };

  // const handleDeleteUser = (user) => {
  //   setAssignedUsers(assignedUsers.filter((assginedUser) => assginedUser !== user));
  // };

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    const saveAssignment = {
      subject: values.subject,
      tenant_id: tenantId,
      firm_id: values.firm,
      firm_to_be_audited_id: values.firm_to_be_audited,
      priority_id: values.priority,
      operation_type_id: values.operationType,
      assignment_status_id: values.assignmentStatus,
      pac_id: values.pac,
      target_complete_date: getDatabaseDate(values.targetCompleteDate),
      assignment_date: getDatabaseDate(values.assignmentDate),
      assign_to: '',
      created_by: loggedInUser.email,
    };

    

    try {
      if (!hasSupervisor()) {
        setErrorMessage(`Please assign at least one Supervisor`);
        setIsLoading(false);
        return;
      } else if (!hasLeadUser()) {
        setErrorMessage(`Please assign at least one Lead User`);
        setIsLoading(false);
        return;
      } else {
        if (assignmentUid) {
          const editedAssignment = {
            ...saveAssignment,
            id: selectedAssignment.id,
            assignment_uid: assignmentUid,
            updatedBy: loggedInUser.email,
          };
          await assignmentApi.put(`/assignments/${assignmentUid}`, editedAssignment, requestHeader);
          await assignmentAssignToApi.delete(`/api/assignmentassigntos/assignmentid/${selectedAssignment.id}`, requestHeader);
          assignmentAssignTos.forEach(async (item) => {
            const assignmentAssignTo = {
              tenant_id: tenantId, 
              assignment_id: selectedAssignment.id || 0,
              assign_to: item.assign_to,
              role_id: item.role_id,
              created_date: getDatabaseDate(Date.now()),
              created_by: loggedInUser.email,
            };
            await assignmentAssignToApi.post("/api/assignmentassigntos", assignmentAssignTo, requestHeader);
          })
        } else {
          const insertedAssignment = await assignmentApi.post("/assignments", saveAssignment, requestHeader);
          assignmentAssignTos.forEach(async (item) => {
            const assignmentAssignTo = {
              tenant_id: tenantId, 
              assignment_id: insertedAssignment.data.body.id || 0,
              assign_to: item.assign_to,
              role_id: item.role_id,
              created_date: getDatabaseDate(Date.now()),
              created_by: loggedInUser.email,
            };
            await assignmentAssignToApi.post("/api/assignmentassigntos", assignmentAssignTo, requestHeader);
          })
        }

        enqueueSnackbar("The assignment save successfully!", {
          variant: "success",
          autoHideDuration: SNACKBAT_AUTO_HIDE_DURATION,
        });
        setIsLoading(false);
        setIsRedirect(true);
      }
    } catch (err) {
      // Handle Error Here
      setErrorMessage(`Failed to create/update an Assignment. Error: ${err}`);
      setIsLoading(false);
      console.error(err);
    }
  };

  const hasRole = (roleUid) => {
    const roleId = roleList.filter(roleItem => roleItem.code_table_uid === roleUid)[0].id;
    const foundAssignmentAssignTos = assignmentAssignTos.filter((item) => item.role_id === roleId)
    if (foundAssignmentAssignTos.length > 0) {
      return true;
    }
    return false;
  };

  const hasSupervisor = () => hasRole(ROLE_SUPERVISOR);

  const hasLeadUser = () => hasRole(ROLE_LEAD_USER);

  const handlerAddAssignmentTo = () => {
    setAssignmentAssignTos([...assignmentAssignTos, { assign_to: "", role_id: 0 }]);
  };

  const handleDeleteAssignmentTo = () => {
    if (assignmentAssignTos.length > 1) {
      assignmentAssignTos.pop();
      setAssignmentAssignTos([...assignmentAssignTos]);
    }
  };

  const handleAssignmentAssignToChange = (event, assignmentAssignTo, index) => {
    assignmentAssignTo.assign_to = event.target.value;
    setAssignmentAssignTos([...assignmentAssignTos]);
  }

  const handleAssignmentRoleChange = (event) => {
    assignmentAssignTos[assignmentAssignTos.length - 1].role_id = event.target.value;
    setAssignmentAssignTos([...assignmentAssignTos]);
  }

  return (
    <Box m="20px">
      {isRedirect && <Navigate to="/assignments" />}
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Header title="ASSIGNMENT" subtitle="Create/Update a Assignment" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={selectedAssignment}
        validationSchema={checkoutSchema}
        enableReinitialize={true} // Important: allow to reload data on useEffect
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, setSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="20px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {errorMessage.length > 0 && (
                <Alert onClose={() => setErrorMessage("")} severity="error" sx={{ gridColumn: "span 4" }}>
                  {errorMessage}
                </Alert>
              )}
              <TextField fullWidth type="text" label="Subject" onBlur={handleBlur} onChange={handleChange} value={values.subject} name="subject" error={!!touched.subject && !!errors.subject} helperText={touched.subject && errors.subject} sx={{ gridColumn: "span 2" }} />
              <FormControl sx={{ gridColumn: "span 2" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker fullWidth label="Assignment Date" value={values.assignmentDate} onBlur={handleBlur} onChange={(value) => setFieldValue("assignmentDate", value)} renderInput={(params) => <TextField error={!!touched.assignmentDate && !!errors.assignmentDate} helperText={touched.assignmentDate && errors.assignmentDate} {...params} />} />
                </LocalizationProvider>
              </FormControl>
              <FormControl sx={{ gridColumn: "span 2" }}>
                <InputLabel id="firm-label">Auditing Firm</InputLabel>
                <Select
                  fullWidth
                  label="Auditing Firm"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firm}
                  name="firm"
                  error={!!touched.firm && !!errors.firm}
                  // helperText={touched.firm && errors.firm}
                >
                  {firms.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.firm_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ gridColumn: "span 2" }}>
                <InputLabel id="firm-to-be-audited-label">Firm To Be Audited</InputLabel>
                <Select fullWidth label="Firm To Be Audited" onBlur={handleBlur} onChange={handleChange} value={values.firm_to_be_audited} name="firm_to_be_audited" error={!!touched.firm_to_be_audited && !!errors.firm_to_be_audited}>
                  {firmsToBeAudited.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.firm_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ gridColumn: "span 2" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker value={values.targetCompleteDate} label="Target Complete Date" onBlur={handleBlur} onChange={(value) => setFieldValue("targetCompleteDate", value)} sx={{ gridColumn: "span 2" }} renderInput={(params) => <TextField error={!!touched.targetCompleteDate && !!errors.targetCompleteDate} helperText={touched.targetCompleteDate && errors.targetCompleteDate} {...params} />} />
                </LocalizationProvider>
              </FormControl>
              <FormControl sx={{ gridColumn: "span 2" }}>
                <InputLabel id="priority-label">Priority</InputLabel>
                <Select
                  fullWidth
                  label="Priority"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.priority}
                  name="priority"
                  error={!!touched.priority && !!errors.priority}
                  // helperText={touched.priority && errors.priority}
                >
                  {codeTables
                    .filter((item) => item.code_table_type_id === 1)
                    .map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.description}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl sx={{ gridColumn: "span 2" }}>
                <InputLabel id="pac-label">PAC</InputLabel>
                <Select
                  fullWidth
                  label="PAC"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.pac}
                  name="pac"
                  error={!!touched.pac && !!errors.pac}
                  // helperText={touched.pac && errors.pac}
                >
                  {codeTables
                    .filter((item) => item.code_table_type_id === 5)
                    .map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.description}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl sx={{ gridColumn: "span 2" }}>
                <InputLabel id="operationType-label">Operation Type</InputLabel>
                <Select
                  fullWidth
                  label="Operation Type"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.operationType}
                  name="operationType"
                  error={!!touched.operationType && !!errors.operationType}
                  // helperText={touched.operationType && errors.operationType}
                  sx={{ gridColumn: "span 2" }}
                >
                  {codeTables
                    .filter((item) => item.code_table_type_id === 2)
                    .map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.description}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl sx={{ gridColumn: "span 2" }}>
                <InputLabel id="assignmentStatus-label">Status</InputLabel>
                <Select
                  fullWidth
                  label="Status"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.assignmentStatus}
                  name="assignmentStatus"
                  error={
                    !!touched.assignmentStatus && !!errors.assignmentStatus
                  }
                  // helperText={touched.assignmentStatus && errors.assignmentStatus}
                  sx={{ gridColumn: "span 2" }}
                >
                  {codeTables
                    .filter((item) => item.code_table_type_id === 3)
                    .map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.description}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            {assignmentAssignTos?.map((assignmentAssignTo, index) => {
              return (
                <Box
                  display="grid"
                  gap="20px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    marginTop: "20px",
                  }}
                >
                  <FormControl sx={{ gridColumn: "span 2" }}>
                    <InputLabel id="assignTo-label">Assign To User</InputLabel>
                    <Select
                      fullWidth
                      label="Assign To User"
                      onBlur={handleBlur}
                      // onChange={(event) => handleAssignToChange(event)}
                      onChange={(e) => handleAssignmentAssignToChange(e, assignmentAssignTo, index)}
                      value={assignmentAssignTo.assign_to}
                      name="assign_to"
                      error={!!touched.assign_to && !!errors.assign_to}
                      // helperText={touched.assignTo && errors.assignTo}
                    >
                      {userList?.map((user) => (
                        <MenuItem key={user.Email} value={user.Email}>
                          {/* {user.Email} - {getRoleByUserName(user.Email)} */}
                          {user.Email}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ gridColumn: "span 1" }}>
                    <InputLabel id="assignTo-label">Assign To Role</InputLabel>
                    <Select
                      fullWidth
                      label="Assign To Roles"
                      onBlur={handleBlur}
                      onChange={handleAssignmentRoleChange}
                      value={assignmentAssignTo.role_id}
                      name="role_id"
                      error={!!touched.role_id && !!errors.role_id}
                      // helperText={touched.assignTo && errors.assignTo}
                    >
                      {roleList?.map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                          {role.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {index === assignmentAssignTos.length-1 && <FormControl sx={{ gridColumn: "span 1" }}>
                    <Stack direction="row" spacing={1}>
                      <Button color="primary" variant="contained"  startIcon={<AddIcon />} onClick={handlerAddAssignmentTo}>
                        Add
                      </Button>
                      <Button color="info" variant="outlined" startIcon={<DeleteIcon />} onClick={handleDeleteAssignmentTo} >
                        Delete
                      </Button>
                    </Stack>
                  </FormControl>}
                </Box>
              );
            })}
            {/* <Box mt="20px">
              {assignedUsers?.map((assignUser) => (
                <Chip style={{ marginRight: "2px" }} variant="outlined" size="small" icon={<FaceIcon />} color="primary" key={assignUser} label={assignUser + " - " + getRoleByUserName(assignUser)} onDelete={() => handleDeleteUser(assignUser)} />
              ))}
            </Box> */}

            <Box display="flex" justifyContent="end" mt="20px">
              <Stack direction="row" spacing={1}>
                <Button type="submit" color="info" variant="outlined" component={Link} to="/assignments" startIcon={<CancelIcon />} onClick={() => setSubmitting(false)}>
                  Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained" disabled={isLoading} startIcon={<SaveIcon />} onClick={() => handleFormSubmit(values)}>
                  Save
                </Button>
              </Stack>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Form;
