import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import securityApi from "../apis/securityApi";
import codeTablePostgressApi from "../apis/codeTablePostgresApi";
import accessManagementApi from "../apis/accessManagementApi";
import { EQIP_ROLE_ADMIN, TENANT_MASTER } from "../utilities/constants";

export const useUsers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const tenantId = useSelector((state) => {
    return state.tenantId;
  }); 

  const idToken = useSelector((state) => {
    return state.token;
  });

  const fetchUsers = async () => {
    try {
        setIsLoading(true);
        const requestHeader = {
          headers: {
            "x-eqip-tenantid": tenantId,
            "Authorization": idToken
          },
        };

        const [responseUsers, responseCodeTables, responseAccessManagements] =
          await Promise.all([
            securityApi.get(`/users`, requestHeader),
            codeTablePostgressApi.get(`/codetables/codetabletypes/10`),
            accessManagementApi.get(`/accessmanagements`)
          ]);
  
        const userList = responseUsers.data?.body?.map((userItem, index) => {
          userItem.id = index; // MUI requires unique id for datagrid
          userItem.Username = userItem.Attributes.filter(attribute => attribute.Name === 'email')[0].Value;
          userItem.tenantId = userItem.Attributes.filter(attribute => attribute.Name === 'custom:tenantId')[0].Value;

          // find access management by user name (email)
          const foundAccMgmt = responseAccessManagements.data.body.filter(accMgmt => accMgmt.user_name === userItem.Username);
          
          if (foundAccMgmt.length > 0) {
            userItem.access_management_uid = foundAccMgmt[0].access_management_uid;
            userItem.tenant_name = foundAccMgmt[0].tenant_name;
            userItem.created_by = foundAccMgmt[0].created_by;
            userItem.created_date = foundAccMgmt[0].created_date;
            userItem.updated_by = foundAccMgmt[0].updated_by;
            userItem.updated_date = foundAccMgmt[0].updated_date;

            // find role
            if (foundAccMgmt[0].roles?.length > 0) {
              const roleId = foundAccMgmt[0].roles[0].id;
              const foundRoles = responseCodeTables?.data?.body?.filter(codeTable => codeTable.code_table_uid === roleId);
              if (foundRoles.length > 0) {
                userItem.role = {
                  roleId: roleId,
                  description: foundRoles[0].description
                };
              }
            }

            // find manager
            if (foundAccMgmt[0].managers?.length > 0) {
              const managerId = foundAccMgmt[0].managers[0].id;
              userItem.manager = {
                managerId: managerId,
              };
            }
          }

          return userItem;
        })
  
        setUsers(userList);
        setManagers(userList);
        setRoleList(responseCodeTables.data.body.filter(role => {
          if (tenantId === TENANT_MASTER || role.code_table_uid !== EQIP_ROLE_ADMIN) {
            return role;
          }
          return null;
        }));
  
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
  };

  useEffect(() => {
    fetchUsers();
  // eslint-disable-next-line   
  }, []);

  const deleteUser = async (selectedUser) => {
    try {
      setIsLoading(true);
      await securityApi.delete(`/users/${selectedUser.Username}`);
      await accessManagementApi.delete(`/accessmanagements/${selectedUser.access_management_uid}`)
      await fetchUsers();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  return { users, managers, roleList, isLoading, fetchUsers, deleteUser };
};