import { useState } from "react";
import { Alert, Box, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// third party import
import { setAllowedCommodities, setAllowedMarkets, setInspection, setLogin, setRole, setRouteList, setTenantId } from "../../state";
import securityApi from "../../apis/securityApi";
import codeTablePostgresApi from "../../apis/codeTablePostgresApi";
import accessManagementApi from "../../apis/accessManagementApi";
import ieqipCoreServiceApi from "../../apis/ieqipCoreServiceApi";

const OneTimePassForm = ({ email, session }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [totp, setTotp] = useState("");
  const [isRegisterSubmitting, setIsRegisterSubmitting] = useState(false);

  const handleRegisterSubmit = async () => {
    try {
      setErrorMessage("");
      setIsRegisterSubmitting(true);

      const result = await securityApi.post("/users/respondtoauthchallenge", {
        Username: email,
        Session: session,
        code: totp,
      });

      if (result.status === 200) {
        await dispatchRoutes(email);
        dispatch(
          setLogin({
            user: {
              email: email,
            },
            refreshToken: result.data.body.AuthenticationResult.RefreshToken,
            token: result.data.body.AuthenticationResult.IdToken,
            accessToken: result.data.body.AuthenticationResult.AccessToken,
          })
        );

        dispatch(
          setInspection({
            isShowInspection: false,
          })
        );
        navigate("/");
      } else {
        setErrorMessage("Invalid Code. Please try again");
      }
    } catch (error) {
      console.error("OneTimePassForm.Exception", error);
      if (error.name.includes('AxiosError')) {
        setErrorMessage("Invalid Code. Please try again. " + error.message);
      } else {
        setErrorMessage(error.message);
      }
    } finally {
      setIsRegisterSubmitting(false);
    }
  };

  const dispatchRoutes = async (userName) => {
    try {
      const responseCodeTables = await codeTablePostgresApi.get(`/codetables/codetabletypes/10,11`);
      const codeTables = responseCodeTables.data.body;

      // fetch routes, roles, and current user's role
      const responseUser = await securityApi.get(`/users/${userName}`);

      const tenantId = responseUser.data.body.UserAttributes.filter((attribute) => attribute.Name === "custom:tenantId")[0].Value;
      const responseTenant = await ieqipCoreServiceApi.get(`/api/tenants/${tenantId}`)

      // fetch current user's role
      const responseAccessManagements = await accessManagementApi.get(`/accessmanagements/username/${userName}`, {
        headers: {
          "x-eqip-tenantid": tenantId,
        },
      });

      if (responseAccessManagements.data.body.length <= 0) {
        throw new Error("User has no role. Please contact administrator for assisstance");
      } else if (responseAccessManagements.data.body[0].roles <= 0) {
        throw new Error("User has no role. Please contact administrator for assisstance");
      }

      const responserRoutes = codeTables.filter((codeTable) => codeTable.code_table_type_id === 11);
      const responseRoles = codeTables.filter((codeTable) => codeTable.code_table_type_id === 10);

      // Find the role of current user
      const role = responseRoles.filter((role) => role.code_table_uid === responseAccessManagements.data.body[0].roles[0].id)[0];

      // Find routes of the found role
      const sortedRoutes = responserRoutes.sort((a, b) => a.metadata.DisplayOrder - b.metadata.DisplayOrder);
      const routes = sortedRoutes.filter((route) => role.metadata.Routes.includes(route.code_table_uid));

      dispatch(
        setRole({
          role: role.description,
        })
      );

      dispatch(
        setTenantId({
          tenantId: tenantId,
        })
      );

      dispatch(
        setAllowedCommodities({
          allowedCommodities: responseTenant.data?.commodities?.split(',') || [],
        })
      );

      dispatch(
        setAllowedMarkets({
          allowedMarkets: responseTenant.data?.markets?.split(',') || [],
        })
      );

      dispatch(
        setRouteList({
          routeList: routes,
        })
      );
    } catch (error) {
      console.error("dispatchRoutes.error", error);
      throw error;
    }
  };

  return (
    <Stack spacing={1}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography>Please fill your one-time password (OTP) from your Google/Microsoft Authenticator App</Typography>
      </Box>
      <Box>
        <TextField fullWidth autoComplete="Code" type="number" label="OTP code" value={totp} onChange={(e) => setTotp(e.target.value)} />
      </Box>
      {errorMessage && (
        <Alert onClose={() => setErrorMessage("")} severity="error">
          {errorMessage}
        </Alert>
      )}
      <Box>
        <LoadingButton loading={isRegisterSubmitting} fullWidth size="large" type="submit" variant="contained" onClick={handleRegisterSubmit}>
          Login
        </LoadingButton>
      </Box>
    </Stack>
  );
};

export default OneTimePassForm;
