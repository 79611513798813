import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Stack,
  Button,
  TextField,
  Backdrop,
  CircularProgress,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { Navigate, useParams, Link } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackbar } from "notistack";

import Header from "../../components/Header";
import { MARKET_INDENTED_LIST, SNACKBAT_AUTO_HIDE_DURATION } from "../../utilities/constants";
import tenantApi from "../../apis/tenantApi";
import ieqipCoreServiceApi from "../../apis/ieqipCoreServiceApi";
import { getUTCDateTime } from "../../utilities/helpers";

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { enqueueSnackbar } = useSnackbar();
  const [errorMessage, setErrorMessage] = useState('');
  const { tenant_id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);

  const [commodities, setCommodities] = useState([]);
  const [markets, setMarkets] = useState([]);

  const [selectedTenant, setSelectedTenant] = useState({
    code: "",
    tenant_name: "", 
    description: "",
    commodities: [],
    markets: [],
  });

  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [selectedcommodities, setSelectedCommodities] = useState([]);

  const loggedInUser = useSelector((state) => {
    return state.user;
  });

  const idToken = useSelector((state) => {
    return state.token;
  });

  const checkoutSchema = yup.object().shape({
    code: yup.string(),
    tenant_name: yup.string().required("required"),
    description: yup.string(),
  });

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    const saveTenant = {
      code: values.code,
      tenant_name: values.tenant_name,
      description: values.description,
      commodities: selectedcommodities?.join(','),
      markets: selectedMarkets?.join(','),
    };

    try {
      const requestHeader = {
        headers: {
          "Authorization": idToken
        },
      };

      if (tenant_id) {
        const existingTenant = await ieqipCoreServiceApi.get(`/api/tenants/${tenant_id}`);
        const editedTenant = {
          ...existingTenant.data,
          commodities: selectedcommodities?.join(','),
          markets: selectedMarkets?.join(','),
          updated_date: getUTCDateTime(),
          updated_by: loggedInUser.email,
        };
        await ieqipCoreServiceApi.put(`/api/tenants/${tenant_id}`, editedTenant, requestHeader);
      } else {
        const newTenant = {
          ...saveTenant,
          is_active: true,
          created_date: getUTCDateTime(),
          created_by: loggedInUser.email,
        };
        
        await ieqipCoreServiceApi.post("/api/tenants", newTenant, requestHeader);
      }

      enqueueSnackbar("The tenant save successfully!", {
        variant: "success",
        autoHideDuration: SNACKBAT_AUTO_HIDE_DURATION,
      });
      setIsRedirect(true);
      setIsLoading(false);
    } catch (err) {
      // Handle Error Here
      setErrorMessage(`Failed to create/update a Tenant. Error: ${err}`)
      setIsLoading(false);
      console.error(err);
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const [responseCommondity, responseMarket] =
      await Promise.all([
        ieqipCoreServiceApi.get(`/api/codetables/typeid/12`),
        ieqipCoreServiceApi.get(`/api/codetables/typeid/13`),
      ]);

      setCommodities(responseCommondity.data);
      setMarkets(responseMarket.data?.sort((a, b) => a.sort_order - b.sort_order));

      // Edit mode
      if (tenant_id) {
        const [responseTenant] =
          await Promise.all([
            tenantApi.get(`/tenants/${tenant_id}`),
          ]);
        setSelectedTenant(responseTenant.data.body[0]);
        setSelectedCommodities(responseTenant.data.body[0]?.commodities?.split(',') || []);
        setSelectedMarkets(responseTenant.data.body[0]?.markets?.split(',') || []);
        setIsLoading(false);
      }
    } catch (err) {
      // Handle Error Here
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCommodityChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCommodities(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleMarketChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedMarkets(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box m="20px">
      {isRedirect && <Navigate to="/tenants" />}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Header title="TENANT" subtitle="Create/Update a Tenant" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={selectedTenant}
        validationSchema={checkoutSchema}
        enableReinitialize={true} // Important: allow to reload data on useEffect
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {errorMessage.length > 0 && 
              <Alert onClose={() => setErrorMessage('')} 
                severity="error"
                sx={{ gridColumn: "span 4" }}
              >
                {errorMessage}
              </Alert>
              }
              <TextField
                fullWidth
                type="text"
                label="Code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.code}
                name="code"
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                type="text"
                label="Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.tenant_name}
                name="tenant_name"
                error={!!touched.tenant_name && !!errors.tenant_name}
                helperText={touched.tenant_name && errors.tenant_name}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                type="text"
                label="Description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.description}
                name="description"
                sx={{ gridColumn: "span 4" }}
              />
              <FormControl sx={{ gridColumn: "span 2" }}>
                <InputLabel id="commodity-label">Commodity</InputLabel>
                <Select multiple fullWidth labelId="commodity-label" label="Commodity" value={selectedcommodities} 
                  onChange={handleCommodityChange}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {commodities.map((option) => (
                    <MenuItem value={option?.code}>
                      <Checkbox 
                        checked={selectedcommodities?.indexOf(option?.code) > -1} 
                      />
                      {`${option.description}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ gridColumn: "span 2" }}>
                <InputLabel id="market-label">Market</InputLabel>
                <Select multiple fullWidth labelId="market-label" label="Market" value={selectedMarkets} 
                  onChange={handleMarketChange}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {markets.map((option) => (
                    <MenuItem value={option?.code}>
                      {MARKET_INDENTED_LIST.includes(option?.code) && <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>}
                      <Checkbox 
                        checked={selectedMarkets?.indexOf(option?.code) > -1} 
                      />
                      {`${option.description}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Stack direction="row" spacing={1}>
                <Button
                  type="submit"
                  color="info"
                  variant="outlined"
                  component={Link}
                  to="/tenants"
                  startIcon={<CancelIcon />}
                  onClick={() => setSubmitting(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isLoading}
                  startIcon={<SaveIcon />}
                >
                  Save
                </Button>
              </Stack>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Form;
