import React from "react";
// import { Link } from "react-router-dom";

import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const LoginComponent = styled(Box)({
  "& svg": {
    fontSize: 100,
  },
});

const Logo = () => {
  return (
    <LoginComponent>
      <Box
        component="img"
        src="/assets/ieqiplogo-3d.gif"
        alt="logo"
        sx={{
          height: 226.4,
          width: 276.8,
          maxHeight: { xs: 113.2, md: 226.4 },
          maxWidth: { xs: 138.4, md: 276.8 },
        }}
      />
      {/* <PersonSearchIcon color="primary" /> */}
    </LoginComponent>
  );
};

export default Logo;
