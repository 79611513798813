import React, { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Button, Box, useTheme, Typography, CircularProgress, Alert } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { DataGrid } from "@mui/x-data-grid";
import RefreshIcon from "@mui/icons-material/Refresh";

import { tokens } from "../../theme";
import Header from "../../components/Header";

import axios from "axios";
// import { getLocalDateTime } from "../../utilities/helpers";
import lookupApi from "../../apis/lookupApi";
import { getLocalDateTime } from "../../utilities/helpers";

const Lookup = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [lookupVersions, setLookupVersions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [uploadFile, setUploadFile] = useState("");

  const columns = [
    {
      field: "created_date",
      headerName: "Created Date",
      flex: 1,
      renderCell: ({ row }) => {
        return <>{getLocalDateTime(row.created_date)}</>;
      },
    },
    {
      field: "updated_date",
      headerName: "Updated Date",
      flex: 1,
      renderCell: ({ row }) => {
        return <>{getLocalDateTime(row.updated_date)}</>;
      },
    },
    {
      field: "lookup_code_type",
      headerName: "Type",
      flex: 1,
    },
    // {
    //   field: "status",
    //   headerName: "status",
    //   flex: 1
    // },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <>
            <>{row.status === "Processing" && <CircularProgress size={20} />}</>
            <>{row.status === "Completed" && <CheckCircleIcon color="success" />}</>
            <>{row.status === "Failed" && <HighlightOffIcon color="fail" />}</>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const lookupVersionsResponse = await lookupApi.get(`/lookups/versions`);
      setLookupVersions(lookupVersionsResponse.data.body);
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
    setIsLoading(false);
  };

  const refresh = async () => {
    await fetchData();
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    setIsLoading(true);
    setErrorMessage(null);
    setUploadFile("");
    if (acceptedFiles?.length > 1) {
      setErrorMessage(`Please upload 1 file`);
    } else {
      const foundFile = acceptedFiles[0];
      setUploadFile(foundFile.name);
      try {
        //1. Get upload presigned url
        const s3PresignedUrlResult = await lookupApi.post(`/lookups/presignedurl`, {
          bucketName: "eqip-cfr-csv-<environment>",
          fileKey: foundFile.name,
        });

        //2. Upload file to s3 using presigned url
        await axios.put(s3PresignedUrlResult.data.body, foundFile);
      } catch (error) {
        console.error("Uploaded failed", error);
      }
    }
    setIsLoading(false);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Box m="20px">
      <Header title="LOOKUPS" subtitle="Upload inspection lookup data" />
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Box>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Box
            sx={{
              p: 2,
              border: "1px dashed grey",
              textAlign: "center",
            }}
          >
            {isDragActive ? (
              <Typography>Drop the files here ...</Typography>
            ) : (
              <Box>
                <Box>
                <Typography>
                  Drag 'n' drop some files here, or click to select files. Please upload <b>csv</b> file only
                </Typography>
                </Box>
                <Box>
                  {uploadFile && `Upload ${uploadFile}`}
                </Box>
              </Box>
            )}
          </Box>
        </div>
      </Box>
      <Box m="10px 0 0 0" display="flex" justifyContent="end">
        <Button color="primary" variant="contained" startIcon={<RefreshIcon />} onClick={() => refresh()}>
          Refresh
        </Button>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.blueAccent[600],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.grey[900],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.grey[900],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.grey[500]} !important`,
          },
        }}
      >
        <DataGrid rows={lookupVersions} columns={columns} loading={isLoading} getRowId={(row) => row.created_date} />
      </Box>
    </Box>
  );
};

export default Lookup;
