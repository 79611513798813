import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, IconButton, useTheme } from "@mui/material";
import { Add } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useFirms } from "../../hooks/useFirms";
import ModalDialog from "../../components/ModalDialog";
import { getLocalDateTime } from "../../utilities/helpers";

const Firms = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {firms, deleteFirm, isLoading} = useFirms();
  const [deletingFirm, setDeletingFirm] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const columns = [
    {
      field: "firm_name",
      headerName: "Firm Name",
      flex: 1,
    },
    {
      field: "no_of_employee",
      headerName: "Firm Size",
      flex: 1,
    },
    {
      field: "firm_type",
      headerName: "Firm Type",
      flex: 1,
    },
    {
      field: "created_by",
      headerName: "Created By",
      flex: 1,
    },
    {
      field: "created_date",
      headerName: "Created Date",
      flex: 1,
      renderCell: ({ row }) => {
        return <>{getLocalDateTime(row.created_date)}</>;
      },
    },
    {
      field: "updated_by",
      headerName: "Updated By",
      flex: 1,
    },
    {
      field: "updated_date",
      headerName: "Updated Date",
      flex: 1,
      renderCell: ({ row }) => {
        return <>{getLocalDateTime(row.created_date)}</>;
      },
    },
    {
      field: "id",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box>
            <IconButton
              color="success"
              aria-label="Edit"
              component={Link}
              to={`/firms/${row.firm_uid}/edit`}
            >
              <EditIcon />
            </IconButton>
            <IconButton color="error" onClick={() => handleDelete(row)} variant="">
                <DeleteIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const handleDeleteOk = async () => {
    setOpenDeleteModal(false);
    await deleteFirm(deletingFirm);
  }

  const handleDeleteCancel = () => {
    setOpenDeleteModal(false);
  }

  const handleDelete = (firm) => {
    setOpenDeleteModal(true);
    setDeletingFirm(firm)
  }

  return (
    <Box m="20px">
      <Header title="FIRMS" subtitle="Managing the Firms" />
      <Box display="flex" justifyContent="end">
        <Button
          color="primary"
          variant="contained"
          startIcon={<Add />}
          component={Link}
          to="/firms/create"
        >
          Create Firm
        </Button>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.blueAccent[600],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.grey[900],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.grey[900],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.grey[500]} !important`,
          },
        }}
      >
        <DataGrid rows={firms} columns={columns} loading={isLoading} />
      </Box>
      <ModalDialog
        title="Delete"
        content="Are you sure you want to delete this item?"
        open={openDeleteModal}
        handleOk={handleDeleteOk}
        handleCancel={handleDeleteCancel} />
    </Box>
  );
};

export default Firms;
