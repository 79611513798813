import { SubMenu } from "react-pro-sidebar";
import Item from "../item";

const Practice = ({lookupcodetype, title, sections, subPart, subPartCode, inspectionUid, selected, setSelected, colors, filterRegulations, existRegulationWithNoCitation, filterCitationLanguages}) => {
    return (
    <SubMenu
      key={lookupcodetype}
      title={title}
      style={{
        color: colors.grey[100],
      }}
    >
      {sections &&
        sections.map((section, index) => {
          if (subPart !== section.parent_uid) {
            subPart = section.parent_uid;
            subPartCode = section.parent_code;
            return (
              <SubMenu
                key={section.parent_uid}
                title={section.parent_description}
                style={{
                  color: colors.grey[100],
                }}
              >
                {sections
                  .filter((item) => item.parent_uid === section.parent_uid)
                  .map((filterSection) => {
                    return (
                      <SubMenu key={filterSection.lookup_uid} title={filterSection.description}>
                        {filterRegulations(filterSection.lookup_uid).map((filterRegulation) => {
                          if (existRegulationWithNoCitation(filterRegulation.regulation_code)) {
                            return <Item key={filterRegulation.lookup_uid} title={filterRegulation.description_with_regulation} to={`/inspection/${inspectionUid}/citationLanguageCode/${filterRegulation.regulation_code}`} selected={selected} setSelected={setSelected} />;
                          } else {
                            return (
                              <SubMenu key={filterRegulation.lookup_uid} title={filterRegulation.description_with_regulation}>
                                {filterCitationLanguages(filterRegulation.regulation_lookup_uid).map((filterCitationLanguage) => (
                                  <Item key={filterCitationLanguage.lookup_uid} title={filterCitationLanguage.description} to={`/inspection/${inspectionUid}/subPartCode/${subPartCode}/citationLanguageCode/${filterCitationLanguage.code}/lookupcodetype/${lookupcodetype}`} selected={selected} setSelected={setSelected} />
                                ))}
                              </SubMenu>
                            );
                          }
                        })}
                      </SubMenu>
                    );
                  })}
              </SubMenu>
            );
          } else {
            return <div key={section.parent_uid + index}></div>;
          }
        })}
    </SubMenu>
  );
};

export default Practice;
