import * as React from "react";
import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getLocalDateTime } from "../../utilities/helpers";
import { tokens } from "../../theme";
import { AUDIT_OPRATIONS } from "../../utilities/constants";

export default function ObservationSignatureAuditDialog({ open, setOpen, assignmentSignatureAuidts, setAssignmentSignatureAudits }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleClose = () => {
    setAssignmentSignatureAudits([]);
    setOpen(false);
  };

  function getRowId(row) {
    return row.stamp;
  }

  const columns = [
    {
      field: "signature_url",
      headerName: "Signature",
      width: 200,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box>
            {row.signature_url && <img src={row.signature_url} alt="Not Found" width="200" height="40" />}
          </Box>
        );
      },
    },
    {
      field: "stamp",
      headerName: "Issued Date",
      width: 150,
      flex: 1,
      renderCell: ({ row }) => {
        return <>{getLocalDateTime(row.stamp)}</>;
      },
    },
    {
      field: "operation",
      headerName: "Operation",
      width: 10,
      flex: 1,
      renderCell: ({ row }) => {
        return <>{AUDIT_OPRATIONS.filter(ao => ao.label === row.operation)[0].value}</>;
      },
    },

  ];

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Signature History</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Please sign here
          </DialogContentText> */}
          <Box
            m="0 0 0 0"
            sx={{
              height: 400, width: 500,
              "& .MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.blueAccent[600],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.grey[900],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.grey[900],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.grey[500]} !important`,
              },
            }}
          >
            <DataGrid rows={assignmentSignatureAuidts} columns={columns}  getRowId={getRowId}/>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" type="submit" onClick={handleClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
