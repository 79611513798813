import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import firmApi from "../apis/firmApi";

export const useFirms = () => {
  const [firms, setFirms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const loggedInUser = useSelector((state) => {
    return state.user;
  });

  const tenantId = useSelector((state) => {
    return state.tenantId;
  }); 

  const idToken = useSelector((state) => {
    return state.token;
  });

  const fetchFirms = async () => {
    setIsLoading(true);
    try {
        const requestHeader = {
          headers: {
            "x-eqip-tenantid": tenantId,
            "Authorization": idToken
          },
        };
        const response = await firmApi.get(`/firms`, requestHeader);
        if (response && response.data) setFirms(response.data.body);
        setIsLoading(false);
    } catch (error) {
        console.error(error);
        setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFirms();
  // eslint-disable-next-line
  }, [ tenantId ]);

  const deleteFirm = async (firm) => {
    try {
      setIsLoading(true);
      await firmApi.delete(`/firms/${firm.firm_uid}`, { data: { updated_by: loggedInUser.email } } );
      setFirms(firms.filter(item => item.firm_uid !== firm.firm_uid));
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  }

  return { firms, deleteFirm, isLoading };
};