import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, IconButton, useTheme } from "@mui/material";
import { Add } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTenants } from "../../hooks/useTenants";
import ModalDialog from "../../components/ModalDialog";
import { getLocalDateTime } from "../../utilities/helpers";

const Tenants = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {tenants, deleteTenant, isLoading} = useTenants();
  const [deletingTenant, setDeletingTenant] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const columns = [
    {
      field: "tenant_name",
      headerName: "Tenant",
      flex: 1,
    },
    {
      field: "commodities",
      headerName: "Commodities",
      flex: 1,
    },
    {
      field: "markets",
      headerName: "Markets",
      flex: 1,
    },
    {
      field: "is_active",
      headerName: "Active",
      flex: 1,
    },
    {
      field: "created_by",
      headerName: "Created By",
      flex: 1,
    },
    {
      field: "created_date",
      headerName: "Created Date",
      flex: 1,
      renderCell: ({ row }) => {
        return <>{getLocalDateTime(row.created_date)}</>;
      },
    },
    {
      field: "id",
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box>
            <IconButton
              color="success"
              aria-label="Edit"
              component={Link}
              to={`/tenants/${row.tenant_uid}/edit`}
              disabled={row?.tenant_name?.includes('eQip')}
            >
              <EditIcon />
            </IconButton>
            <IconButton color="error" onClick={() => handleDelete(row)} variant="" disabled={row?.tenant_name?.includes('eQip')}>
                <DeleteIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const handleDeleteOk = async () => {
    setOpenDeleteModal(false);
    await deleteTenant(deletingTenant);
  }

  const handleDeleteCancel = () => {
    setOpenDeleteModal(false);
  }

  const handleDelete = (tenant) => {
    setOpenDeleteModal(true);
    setDeletingTenant(tenant)
  }

  return (
    <Box m="20px">
      <Header title="TENANTS" subtitle="Managing the Tenants" />
      <Box display="flex" justifyContent="end">
        <Button
          color="primary"
          variant="contained"
          startIcon={<Add />}
          component={Link}
          to="/tenants/create"
        >
          Create Tenant
        </Button>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.blueAccent[600],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.grey[900],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.grey[900],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.grey[500]} !important`,
          },
        }}
      >
        <DataGrid rows={tenants} columns={columns} loading={isLoading} />
      </Box>
      <ModalDialog
        title="Delete"
        content="Are you sure you want to delete this item?"
        open={openDeleteModal}
        handleOk={handleDeleteOk}
        handleCancel={handleDeleteCancel} />
    </Box>
  );
};

export default Tenants;
