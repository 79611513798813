import { Paper, Chip } from "@mui/material";
import styled from "@emotion/styled";
import { CONVERT_SQUARE_BRACKET_TO_ARRAY } from "../../utilities/constants";

const PaperStyle = styled(Paper)({
  margin: "0.3em",
});

const CitationStatementChip = ({
  selectedCitationLanguage,
  setSelectedCitationLanguage,
}) => {
  // store citation options in [xyz] pattern into the array
  const chipValues = selectedCitationLanguage?.match(CONVERT_SQUARE_BRACKET_TO_ARRAY);
  const handleClick = (chipValue) => () => {
    const [chipLabel, chipFlag] = chipValue.replace('[', '').split('|');
    const updatedValue = selectedCitationLanguage.replace(
      chipValue,
      `${chipLabel}|${chipFlag === "1" ? "0" : "1"}`
    );
    setSelectedCitationLanguage(updatedValue);
  };

  return (
    <PaperStyle>
      {selectedCitationLanguage?.split("<#>").map((item) => {
        let foundChip;
        chipValues?.forEach((chipValue) => {
          chipValue = chipValue.replace('[', '');
          const [chipLabel, chipFlag] = chipValue.split("|");
          if (item.trim() === `[${chipValue}]`) {
            if (chipFlag === "1") {
              foundChip = (
                <Chip
                  key={chipLabel}
                  size="small"
                  color="primary"
                  label={chipLabel}
                  onClick={handleClick(chipValue)}
                />
              );
            } else {
              foundChip = (
                <Chip
                  key={chipLabel}
                  size="small"
                  label={chipLabel}
                  onClick={handleClick(chipValue)}
                />
              );
            }
          }
        });
        return foundChip ? foundChip : item + " ";
      })}
    </PaperStyle>
  );
};

export default CitationStatementChip;
