import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

// third party import
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import { MFA_TYPES } from "../../utilities/constants";

import { Alert, Box, Checkbox, FormControlLabel, IconButton, InputAdornment, Link, Stack, TextField } from "@mui/material";
import { motion } from "framer-motion";

// Project import
import securityApi from "../../apis/securityApi";
import MfaRegisterForm from "./MfaRegisterForm";
import OneTimePassForm from "./OneTimePassForm";

let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const LoginForm = ({ setIsLoginForm }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [challengeName, setChallengeName] = useState(MFA_TYPES.SOFTWARE_TOKEN_MFA);
  const [secretCode, setSecretCode] = useState();
  const [softwareTokenSession, setSoftwareTokenSession] = useState();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Provide a valid email address").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      try {
        setErrorMessage("");
        const resultAuth = await startAuthenticate();
        switch (resultAuth.ChallengeName) {
          case MFA_TYPES.MFA_SETUP:
            const resultAssociateSoftToken = await associateSoftwareToken(resultAuth.Session);
            setSecretCode(resultAssociateSoftToken.SecretCode);
            setSoftwareTokenSession(resultAssociateSoftToken.Session);
            setChallengeName(resultAuth.ChallengeName);
            break;
          case MFA_TYPES.SOFTWARE_TOKEN_MFA:
            setChallengeName(MFA_TYPES.TOKEN_MFA_VALIDATION);
            setSoftwareTokenSession(resultAuth.Session);
            break;
          default:
            break;
        }
      } catch (error) {
        console.error("LoginForm.Exception", error);
        setErrorMessage("Invalid username/password. Or you haven't verify your account via email.");
      }
    },
  });

  const startAuthenticate = async () => {
    const response = await securityApi.post("/users/startauthentication", {
      Username: values.email,
      Password: values.password,
    });
    return response.data.body;
  };

  const associateSoftwareToken = async (session) => {
    const response = await securityApi.post("/users/associatesoftwaretoken", {
      Session: session,
    });
    return response.data.body;
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      {challengeName === "SOFTWARE_TOKEN_MFA" && (
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box
              component={motion.div}
              animate={{
                transition: {
                  staggerChildren: 0.55,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                }}
                component={motion.div}
                initial={{ opacity: 0, y: 40 }}
                animate={animate}
              >
                <TextField fullWidth autoComplete="username" type="email" label="Email Address" {...getFieldProps("email")} error={Boolean(touched.email && errors.email)} helperText={touched.email && errors.email} />

                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  {...getFieldProps("password")}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword((prev) => !prev)}>{showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}</IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errorMessage && (
                  <Alert onClose={() => setErrorMessage("")} severity="error">
                    {errorMessage}
                  </Alert>
                )}
              </Box>

              <Box component={motion.div} initial={{ opacity: 0, y: 20 }} animate={animate}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                  <FormControlLabel control={<Checkbox {...getFieldProps("remember")} checked={values.remember} />} label="Remember me" />

                  <Link component={RouterLink} variant="subtitle2" to="#" underline="hover" onClick={() => setIsLoginForm(false)}>
                    Forgot password?
                  </Link>
                </Stack>

                <LoadingButton loading={isSubmitting} fullWidth size="large" type="submit" variant="contained">
                  Login
                </LoadingButton>
              </Box>
            </Box>
          </Form>
        </FormikProvider>
      )}
      {challengeName === MFA_TYPES.MFA_SETUP && <MfaRegisterForm email={values.email} secretCode={secretCode} session={softwareTokenSession} setChallengeName={setChallengeName} />}
      {challengeName === MFA_TYPES.TOKEN_MFA_VALIDATION && <OneTimePassForm email={values.email} session={softwareTokenSession} />}
    </>
  );
};

export default LoginForm;
