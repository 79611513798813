import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const ModalDialog = (props) => {
  const {title, content, open, handleOk, handleCancel, hideOK, hideCancel, isLoading} = props;
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h4">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!hideCancel && (
          <Button
            onClick={handleCancel}
            disabled={isLoading}
            color="info"
            variant="outlined"
            autoFocus
          >
            Cancel
          </Button>
        )}
        {!hideOK && (
          <LoadingButton 
            loading={isLoading} 
            onClick={handleOk}
            variant="contained">
              Ok
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ModalDialog;
