import documentApi from "../apis/documentApi";
import langchainApi from "../apis/langchainApi";
import axios from 'axios';
import { getLastElementStringArray } from "./helpers";

const pipeconeHeader = {
    'x-eqip-openai-api-key': process.env.REACT_APP_OPENAI_API_KEY,
    'x-eqip-openai-modal': process.env.REACT_APP_OPENAI_MODAL,
    'x-eqip-pipecone-api-key': process.env.REACT_APP_PIPECONE_API_KEY,
    'x-eqip-pipecone-environment': process.env.REACT_APP_PIPECONE_ENVIRONMENT,
    'x-eqip-pipecone-index': process.env.REACT_APP_PIPECONE_INDEX,
    'x-eqip-pipecone-search-similarity-count': 10
  };

export const preditMessage = async (message) => {
    const body = {
        message: message
    }
    const result = await langchainApi.post(`/pinecone/askme`, body, { headers: pipeconeHeader });
    return result?.data;
}

export const preditSimilarMessages = async (message) => {
    const body = {
        message: message
    }
    const result = await langchainApi.post(`/pinecone/askmereturnsimilaranswers`, body, { headers: pipeconeHeader });
    return result?.data;
}

export const preditMessageWithReferences = async (message, namespace) => {
    const body = {
        message: message,
        namespace: namespace
    }
    const result = await langchainApi.post(`/pinecone/askieqip`, body, { headers: pipeconeHeader });
    return result?.data;
}

// Upload file to Pipecone which is AI Vector Database
export const uploadFileToPipecone = async (file, loginUserName, commodity, market) => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        const namespace = `${commodity}_${market}`;
        formData.append('namespace', namespace);

        //1.Check file exist in database
        const decodeUrl = decodeURI(`/ai/pipeconedocuments/filename/${file.name}`);

        const getAiDocumentsResult = await documentApi.get(decodeUrl);
        if (getAiDocumentsResult.data?.body?.length > 0) {
            throw Error(`The file has uploaded before. Please use different file.`)
        }

        //2. Upload file to pipecone
        const result = await langchainApi.post(`/pinecone/pdf/upload`, formData, { headers: {
            ...pipeconeHeader,
            'Content-Type': 'multipart/form-data'
            } 
        });

        //3. Insert file detail to database
        await documentApi.post(`/ai/pipeconedocuments`, {
            name: file.name,
            file_name: result.data.file.filename,
            commodity: commodity,
            market: market,
            created_by: loginUserName,
        });

        //4. Get upload presigned url
        const s3PresignedUrlResult = await documentApi.post(`/ai/pipeconedocuments/presignedurlupload`, {
            objectKey: result.data.file.filename,
            contentType: file.type
        });

        //5. Upload file to s3 using presigned url
        await axios.put(s3PresignedUrlResult.data.body, file);

        return {...result, file};
    } catch (error) {
        return {
            file,
            'error': error.message,
            'status': 500
        };
    }
}

export const scrapingWebsiteFormUrl = async (websiteUrl, loginUserName, commodity, market) => {
    try {
        const namespace = `${commodity}_${market}`;
        const websiteUrlLastElement = getLastElementStringArray(websiteUrl, '/');

        //1. Check file exist in database
        const decodeUrl = decodeURI(`/ai/pipeconedocuments/filename/${websiteUrlLastElement}`);
        const getAiDocumentsResult = await documentApi.get(decodeUrl);
        if (getAiDocumentsResult.data?.body?.length > 0) {
            throw Error(`The Website Url has uploaded before. Please use different Website Url.`)
        }
        
        //2. Upload file to pipecone
        pipeconeHeader['x-eqip-pinecone-namespace'] = namespace;
        const body = {
            websiteUrl: websiteUrl
        };
        const result = await langchainApi.post(`/pinecone/scrapingwebsite/upload`, body, { headers: pipeconeHeader });

        //3. Insert file detail to database
        await documentApi.post(`/ai/pipeconedocuments`, {
            name: websiteUrlLastElement,
            file_name: websiteUrl,
            commodity: commodity,
            market: market,
            created_by: loginUserName,
        });

        return {...result, websiteUrl};
    } catch (error) {
        return {
            websiteUrl,
            'error': error.message,
            'status': 500
        };
    }
}

// Filter: file name without 'upload' prefix
export const deleteVectorIDsByFilter = async (filter, namespace) => {
    pipeconeHeader['x-eqip-pinecone-source'] = filter; 
    pipeconeHeader['x-eqip-pinecone-namespace'] = namespace;
    const result = await langchainApi.post(`/pinecone/deletevectorids`, null, { headers: pipeconeHeader });
    return result?.data;
}