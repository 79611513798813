import React, { useState } from "react";
import { Grid, TextField, Paper, InputBase, IconButton, CircularProgress, Link } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { preditMessage } from "../../utilities/llm";

const GptForm = () => {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [sourceDocuments, setSourceDocuments] = useState([]);

  const onHandleSend = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");
    try {
      if (title) {
        const response = await preditMessage(title);
        if (response) {
          setMessage(`${response.text?.trim()}`);
          setSourceDocuments(response.sourceDocuments);
        } else {
          setMessage(`Sorry, ieQip AI doesn't have this data`);
        }
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <form>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper component="form" elevation={3} sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}>
            <InputBase sx={{ ml: 1, flex: 1 }} color="info" placeholder="Send a message..." inputProps={{ "aria-label": "Send a message..." }} value={title} onChange={(e) => setTitle(e.target.value)} />
            <IconButton type="submit" color="primary" sx={{ p: "10px" }} aria-label="search" onClick={onHandleSend}>
              {loading ? <CircularProgress size={23} /> : <SendIcon color="primary" />}
            </IconButton>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            inputProps={{
              readOnly: true,
            }}
            multiline
            rows={20}
            maxRows={10}
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          Reference: 
        </Grid>
        <Grid item xs={12}>
          {
            sourceDocuments.map(sourceDocument => {
              return <Link href="#">{sourceDocument.metadata.source.split("/")[1]}</Link>
            })
          }
        </Grid>
      </Grid>
    </form>
  );
};

export default GptForm;
