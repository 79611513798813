import { Grid, Paper, useTheme } from "@mui/material";
import styled from "@emotion/styled";

import { tokens } from "../../../theme";
import {
  getCodeTableDescriptionByCodeTableUid,
  getLookupDescriptionByCode,
} from "../../../utilities/helpers";

const CoverageReport = ({
  inspection,
  codeTables,
  lookups,
  systemChoiceUid,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const ItemHeader = styled(Paper)(({ theme }) => ({
    backgroundColor: colors.blueAccent[100],
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
  }));

  const ItemDetail = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
  }));

  // filter quality_protocol by system choice
  const foundProtocolQualities = inspection?.protocol_quality?.filter(
    (protocol_quality) =>
      protocol_quality?.detail?.systemChoice === systemChoiceUid
  );

  return (
    <>
      {foundProtocolQualities &&
        foundProtocolQualities.map((foundProtocolQuality) => (
          <Grid container key={foundProtocolQuality.code} spacing={1}>
            <Grid item xs={12} style={{marginTop:'0.5em'}}>
              <ItemHeader>
                {getLookupDescriptionByCode(
                  lookups,
                  foundProtocolQuality?.code
                )}
              </ItemHeader>
            </Grid>
            <Grid item xs={12} style={{marginLeft:'0.5em'}}>
              <ItemDetail>
                {getCodeTableDescriptionByCodeTableUid(
                  codeTables,
                  foundProtocolQuality?.detail?.coverageChoice
                )}
              </ItemDetail>
            </Grid>
            {foundProtocolQuality?.detail?.coverageComment && (
              <Grid item xs={12} style={{marginLeft:'1em'}}>
                <ItemDetail>
                  {foundProtocolQuality?.detail?.coverageComment}
                </ItemDetail>
              </Grid>
            )}
          </Grid>
        ))}
    </>
  );
};

export default CoverageReport;
