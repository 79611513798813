import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Alert, Box, FormControl, InputLabel, Select, TextField, MenuItem, Stack, Button } from '@mui/material';
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { Formik } from 'formik';
import { useSnackbar } from "notistack";
import * as yup from "yup";
import { ROLE_ADMIN, SNACKBAT_AUTO_HIDE_DURATION } from '../../utilities/constants';
import accessManagementApi from '../../apis/accessManagementApi';

const RoleFormModal = ({roleList, managers, selectedUser, setRoleModal, fetchUsers}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const loggedInUser = useSelector((state) => {
    return state.user;
  });

  const defaultUser = {
    Username: selectedUser.Username,
    selectedRole: selectedUser.role?.roleId,
    selectedManager: selectedUser.manager?.managerId,
  }

  const checkoutSchema = yup.object().shape({
    Username: yup.string(),
    selectedRole: yup.string(),
    selectedManager: yup.string(),
  });

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    const saveUser = {
      tenant_id: selectedUser.tenantId,
      user_name: values.Username,
      roles: [{id: values.selectedRole}],
      managers: [{id: values.selectedManager}],
      created_by: loggedInUser.email,
      updated_by: loggedInUser.email,
    };

    try {
      
      await accessManagementApi.post(`/accessmanagements`, saveUser, {
        headers: {
          'x-eqip-tenantid': selectedUser.tenantId
        }
      });

      enqueueSnackbar("Set role successfully!", {
        variant: "success",
        autoHideDuration: SNACKBAT_AUTO_HIDE_DURATION,
      });
      await fetchUsers();
      setRoleModal(false);
      setIsLoading(false);
    } catch (err) {
      // Handle Error Here
      setErrorMessage(`Failed to set a role. Error: ${err}`)
      setIsLoading(false);
      console.error(err);
    }
  };

  return (
    <Formik
        onSubmit={handleFormSubmit}
        initialValues={defaultUser}
        validationSchema={checkoutSchema}
        enableReinitialize={true} // Important: allow to reload data on useEffect
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": "span 4",
                "marginTop": "10px"
              }}
            >
              {errorMessage.length > 0 && 
              <Alert onClose={() => setErrorMessage('')} 
                severity="error"
                sx={{ gridColumn: "span 4" }}
              >
                {errorMessage}
              </Alert>
              }
              <FormControl sx={{ gridColumn: "span 4" }}>
                <TextField
                  id="standard-read-only-input"
                  label="User name"
                  value={values.Username}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
              <FormControl sx={{ gridColumn: "span 4" }}>
                <InputLabel id="roleId" fullWidth>Role</InputLabel>
                <Select labelId="roleListId" id="roleListId"
                  fullWidth
                  label="Role"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.selectedRole}
                  name="selectedRole"
                >
                  {roleList.map(option =>
                    <MenuItem key={option.code_table_uid} value={option.code_table_uid}>{option.description}</MenuItem>)}
                </Select>
              </FormControl >
              {
                selectedUser !== ROLE_ADMIN && 
                <FormControl sx={{ gridColumn: "span 4" }}>
                  <InputLabel id="managerId" fullWidth>Manager</InputLabel>
                  <Select 
                    fullWidth
                    label="Manager"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.selectedManager}
                    name="selectedManager"
                  >
                    {managers.filter(manager => manager.Username !== selectedUser.Username).map(option => 
                      <MenuItem key={option.Username} value={option.Username}>{option.Username}</MenuItem>)}
                  </Select>
                </FormControl>
              }
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Stack direction="row" spacing={1}>
                <Button
                  color="info"
                  variant="outlined"
                  startIcon={<CancelIcon />}
                  onClick={() => setRoleModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isLoading}
                  startIcon={<SaveIcon />}
                >
                  Save
                </Button>
              </Stack>
            </Box>
          </form>
        )}
      </Formik>
  )
}

export default RoleFormModal