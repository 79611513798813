import React, { useState } from "react";
// import { Link as RouterLink } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  Divider,
  Card,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import Logo from "../../components/Logo";
import LoginForm from "./LoginForm";
import { tokens } from "../../theme";
import LostPasswordForm from "./LostPasswordForm";

const Login = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isLoginForm, setIsLoginForm ] = useState(true);

  //////////////////////////////////
  const RootStyle = styled("div")({
    background: colors.grey[900],
    height: "100vh",
    display: "grid",
    placeItems: "center",
  });

  const HeadingStyle = styled(Box)({
    textAlign: "center",
  });

  const ContentStyle = styled(Card)({
    maxWidth: 480,
    padding: 25,
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    background: "#fff",
  });

  let easing = [0.6, -0.05, 0.01, 0.99];
  const fadeInUp = {
    initial: {
      y: 60,
      opacity: 0,
      transition: { duration: 0.6, ease: easing },
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        ease: easing,
      },
    },
  };
  return (
    <RootStyle>
      <Container maxWidth="sm">
        <ContentStyle>
          <HeadingStyle component={motion.div} {...fadeInUp}>
            <Logo />
            <Typography sx={{ color: "text.secondary" }}>
            {isLoginForm ? "Login to your ieQip account" : "Reset password to your ieQip account"}
            </Typography>
          </HeadingStyle>

          <Divider
            sx={{ my: 3 }}
            component={motion.div}
            {...fadeInUp}
          ></Divider>

          {isLoginForm ? <LoginForm setIsLoginForm={setIsLoginForm}/> : <LostPasswordForm setIsLoginForm={setIsLoginForm}/>}

          {/* <Typography
            component={motion.p}
            {...fadeInUp}
            variant="body2"
            align="center"
            sx={{ mt: 3 }}
          >
            Don’t have an account?{" "}
            <Link variant="subtitle2" component={RouterLink} to="/signup">
              Sign up
            </Link>
          </Typography> */}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
};

export default Login;
