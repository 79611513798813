import React, { useState } from "react";
import { Droppable, DragDropContext } from "react-beautiful-dnd";
import { Grid } from "@mui/material";
import ObservationDraggableListItem from "./ObservationDraggableListItem";
import EqipAccordin from "../../components/EqipAccordin";

const ObservationDragDropList = ({
  inspection,
  setInspection,
  codeTable,
  questionCitationsPerSystemChoice,
  reportType,
}) => {
  const [questionCitationSystemChoice, setQuestionCitationSystemChoice] =
    useState(questionCitationsPerSystemChoice);
  const handleDragEnd = async (result) => {
    const { destination, source } = result;
    let newItems = Array.from(questionCitationSystemChoice);
    const [removed] = newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, removed);

    // revert to original indexes
    let tempIndexes = [];
    questionCitationSystemChoice.forEach((element) => {
      tempIndexes.push(element.index);
    });

    newItems.forEach((newItem, i) => {
      newItem.index = tempIndexes[i];
      inspection?.protocol_quality?.forEach((question) => {
        if (question.code === newItem.code) {
          question.index = newItem.index;
        }
      });
    });

    setQuestionCitationSystemChoice(newItems);
  };

  return (
    <EqipAccordin title={codeTable.description}>
      <Grid container>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable-list">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {questionCitationSystemChoice.map((questionCitation, index) => {
                  if (questionCitation) {
                    const { citationLanguage, citationSpecifically } =
                      questionCitation.detail;
                    return (
                      questionCitation && (
                        <ObservationDraggableListItem
                          key={questionCitation.code}
                          index={index}
                          inspection={inspection}
                          setInspection={setInspection}
                          questionCitation={questionCitation}
                          codeTable={codeTable}
                          citationLanguage={citationLanguage}
                          citationSpecifically={citationSpecifically}
                          reportType={reportType}
                        />
                      )
                    );
                  }
                  return undefined;
                })}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
    </EqipAccordin>
  );
};

export default ObservationDragDropList;
