import React from 'react';
import { SubMenu } from 'react-pro-sidebar';
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Item from './item';

const Pai = ({paiData, inspectionUid, selected, setSelected}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return <>
    {paiData.map((paiItem) => {
        return !paiItem.parent_uid && <SubMenu
          key={paiItem.lookup_uid}
          title={paiItem.description}
          style={{
            color: colors.grey[100],
          }}
        >
          {paiData
            .filter(element => element.parent_uid === paiItem.lookup_uid)
            .map(childPaiItem => <Item
              key={childPaiItem.lookup_uid}
              title={childPaiItem.description}
              to={`/inspection/${inspectionUid}/citationLanguageCode/${childPaiItem.code}`}
              selected={selected}
              setSelected={setSelected}
              />)
          }
        </SubMenu>
      })
    }
  </> 
}

export default Pai;
