import { Alert, Box, Stack, TextField, Typography } from "@mui/material";
import { QRCodeCanvas } from "qrcode.react";
import { LoadingButton } from "@mui/lab";
import securityApi from "../../apis/securityApi";
import { useState } from "react";
import { MFA_TYPES } from "../../utilities/constants";

const MfaRegisterForm = ({ email, secretCode, session, setChallengeName }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [totp, setTotp] = useState("");
  const [isRegisterSubmitting, setIsRegisterSubmitting] = useState(false);

  const handleRegisterSubmit = async () => {
    try {
      setErrorMessage("");
      setIsRegisterSubmitting(true);
      const result = await securityApi.post("/users/verifysoftwaretoken", {
        Session: session,
        totp: totp,
      });

      if (result.status === 200) {
        setSuccessMessage("Great news! Your multi-factor authentication (MFA) registration is all set. You'll be redirected to the login page in just 3 seconds. See you there!");
        setTimeout(function () {
          //Start the timer
          setChallengeName(MFA_TYPES.SOFTWARE_TOKEN_MFA);
        }, 3000); //After 3 second, redirect to login screen
      } else {
        setChallengeName(MFA_TYPES.MFA_SETUP);
        setErrorMessage("Invalid code. MFA registration failed. Retry or reach out for help!");
      }
    } catch (error) {
      console.error("MfaRegisterForm.Exception", error);
      setErrorMessage("Invalid Code. Failed to register MFA");
    } finally {
      setIsRegisterSubmitting(false);
    }
  };

  return (
    <Stack spacing={1}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <QRCodeCanvas value={`otpauth://totp/iEqip: ${email}?secret=${secretCode}`} />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography>Scan the QR Code using your Google/Microsoft Authenticator app from the Google Play Store or Apple App Store to set up Multi-factor Authentication (MFA).</Typography>
      </Box>
      <Box>
        <TextField fullWidth autoComplete="Code" type="number" label="Enter the code from Google/Microsoft Authenticator" value={totp} onChange={(e) => setTotp(e.target.value)} />
      </Box>
      {errorMessage && (
        <Alert onClose={() => setErrorMessage("")} severity="error">
          {errorMessage}
        </Alert>
      )}
      {successMessage && (
        <Alert onClose={() => setSuccessMessage("")} severity="success">
          {successMessage}
        </Alert>
      )}
      <Box>
        <LoadingButton loading={isRegisterSubmitting} fullWidth size="large" type="submit" variant="contained" onClick={handleRegisterSubmit}>
          Register
        </LoadingButton>
      </Box>
    </Stack>
  );
};

export default MfaRegisterForm;
