import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Divider, Link } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import EllipsisText from "../global/EllipsisText";
import { handleDownloadFileFromS3 } from "../../utilities/helpers";
import documentApi from "../../apis/documentApi";

export default function AskMeAnswer({ title, messages, message, reference }) {
  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {!messages && (
            <div>
              <Typography>{message}</Typography>
              <Link href="#">{reference?.split("/")[1]}</Link>
            </div>
          )}
          {messages &&
            messages?.map((message, index) => (
              <List component="nav" aria-label="mailbox folders">
                <ListItem>
                  <EllipsisText text={message.text ? message.text : message.pageContent} />
                </ListItem>
                {message.metadata.source && (
                  <>
                    <ListItem>
                      {message.metadata.source.search('pdf') > 0 ?
                        <Link component="button" aria-label="download file" onClick={() => handleDownloadFileFromS3(documentApi, message.metadata.source.split("/")[1], message.metadata.source.split("/")[1], '/ai/pipeconedocuments/presignedurldownload')}>
                          {message.metadata.source.split("/")[1]}
                        </Link>
                        :
                        <Link target="_blank" href={message.metadata.source}>
                          {message.metadata.source}
                        </Link>
                      }

                    </ListItem>
                    <Divider />
                  </>
                )}
              </List>
            ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
