import { Grid, Paper, useTheme } from "@mui/material";
import styled from "@emotion/styled";

import { tokens } from "../../../theme";
import { getLookupDescriptionByCode } from "../../../utilities/helpers";

const GeneralReport = ({ inspection, lookups }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const ItemHeader = styled(Paper)(({ theme }) => ({
    backgroundColor: colors.blueAccent[100],
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
  }));

  const ItemDetail = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
  }));

  // filter quality_protocol by code
  const foundProtocolQualities = inspection?.protocol_quality?.filter((protocol_quality) => {
    return protocol_quality?.code?.startsWith("SM");
  });

  return foundProtocolQualities && 
    <>
      {foundProtocolQualities.map((foundProtocolQuality) => {
        return (
          <Grid container key={foundProtocolQuality.code}>
            <Grid container item spacing={1}>
                <Grid item xs={12} style={{marginTop:'0.5em'}}>
                    <ItemHeader>{getLookupDescriptionByCode(lookups, foundProtocolQuality?.code)}</ItemHeader>
                </Grid>
                <Grid item xs={12} style={{marginTop:'0.5em', marginLeft:'0.5em'}}>
                    <ItemDetail>{foundProtocolQuality?.detail?.coverageComment}</ItemDetail>
                </Grid>
            </Grid>
          </Grid>
        );
      })}
    </>
};

export default GeneralReport;
