import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import ObservationDragDropList from './ObservationDragDropList';
import { COVERAGE_CHOICE_CITATION_GUID, COVERAGE_CHOICE_COVERED_DISCUSS_ONLY_GUID, REPORT_TYPE } from '../../utilities/constants';
import styled from '@emotion/styled';
import inspectionApi from '../../apis/inspectionApi';
import codeTablePostgresApi from '../../apis/codeTablePostgresApi';


const ObservationReport = ({ reportType }) => {
    const [inspection, setInspection] = useState(null);
    const [codeTables, setCodeTables] = useState([]);
    const [loading, setLoading] = useState(false);
    const [questionCitations, setQuestionCitations] = useState([]);

    const { inspectionUid } = useParams();

    const RootStyle = styled("div")({
        flexGrow: 1,
        margin: "20px",
      });

    let coverageChoice = COVERAGE_CHOICE_CITATION_GUID;
    switch (reportType) {
        case REPORT_TYPE.OBSERVATION:
            coverageChoice = COVERAGE_CHOICE_CITATION_GUID;
            break;
        case REPORT_TYPE.DISCUSSION:
            coverageChoice = COVERAGE_CHOICE_COVERED_DISCUSS_ONLY_GUID
            break;
        default:
            coverageChoice = COVERAGE_CHOICE_CITATION_GUID;
            break;
    }
    
    useEffect(() => {
        (async () => {
            setLoading(true);
            const [responseInspection, responseCodeTables] =
                await Promise.all([inspectionApi.get(`/inspections/${inspectionUid}`),
                    codeTablePostgresApi.get(`/codetables/codetabletypes/6`) // CodeTableType is System Choice
                ]);
            
            // Create seperate QuestionCitations array and use it to display
            const tempProtocolQualities = [];
            let citationIndex = 0;
            responseInspection?.data.body[0]?.protocol_quality?.forEach(protocolQuality => {
                const detail = protocolQuality?.detail;
                    if (protocolQuality?.detail?.coverageChoice === coverageChoice) {
                        if (!detail.index) {
                            detail.index = citationIndex++;
                        }
                        tempProtocolQualities.push(protocolQuality);
                    }
                    return undefined;
            });

            tempProtocolQualities.sort((a,b)=> a?.index - b?.index);

            setInspection(responseInspection.data.body[0]);

            if (responseInspection.data.body[0].system_choice_observation) {
                setCodeTables(responseInspection.data.body[0].system_choice_observation);
            } else {
                setCodeTables(responseCodeTables.data.body);
            }

            setQuestionCitations(responseInspection?.data.body[0]?.protocol_quality);
            setLoading(false);
        })();
    }, [inspectionUid, coverageChoice]);


    return <RootStyle>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>  
        <Button variant="contained" color="primary" style={{ marginBottom: '0.5em' }} 
            href={`/inspection/${inspectionUid}/${reportType === REPORT_TYPE.OBSERVATION ? 'printcitation': 'printdiscussion' }`}>Print {reportType}</Button>
        {codeTables && codeTables.map((codeTable) => {
            // Filter questionCitation where coverageChoice 
            // either citation or observation
            const questionCitationsPerSystemChoice = questionCitations?.filter(
                p => p.detail?.systemChoice === codeTable.code_table_uid &&
                p.detail?.coverageChoice === coverageChoice);

            return questionCitationsPerSystemChoice?.length > 0 &&
                <ObservationDragDropList key={questionCitationsPerSystemChoice[0].code} inspection={inspection} setInspection={setInspection} codeTable={codeTable} 
                    questionCitationsPerSystemChoice={questionCitationsPerSystemChoice} reportType={reportType} />
        })}
    </RootStyle>
}

export default ObservationReport;